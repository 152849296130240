export const apis = {
  otp: "/otp",
  verify_otp: "/verify/otp",
  update_address: "/user/save/address",
  charges: "/charges",
  fetch_address: "/user/fetch/address",
  create_order: "/create/order",
  payment_update: "/payment/update",
  dashboard_users: "/dashboard/usr",
  corp_users: "/dashboard/corp/admin/users",
  dashboard_orders: "/dashboard/ord",
  dashboard_orders_count: "/dashboard/ord/count",
  orders_status_count: "/dboard/ord/status/count",
  orders_status_update: "/order/status/update",
  dashboard_users_count: "/dashboard/usr/count",
  dashboard_signup_count: "/dashboard/signup/count",
  corporate_register: "/corp/register",
  corporate_login: "/corp/login",
  corporate_user_registers: "/corp/user/register",
  connections: "/connection/fetch",
  connection_add: "/connection/add",
  corporate_connections: "/corp/linked/connections",
  corporate_card_usage: "/corp/cards/count",
  users: "/userdata",
  qr_codes_fetch: "/fetch/nfc/qr/master",
  save_qr_nfc: "/nfc/qr/save",
  download_qr_nfc: "/download/nfc/master"
}