import _ from "lodash";
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import badge from '../../assets/metal_card.png';
import del from '../../assets/delete.png';
import { apis } from '../../utils/apis';
import { calculateDiscount, getAccessories, isAddress } from '../../utils/helper';
import useAxios from '../../utils/useAxios';

const coupons = {
  VIPKOB: 50,
  STUDENTKOB: 25,
  FIRSTKOB: 20,
  REFERALKOB: 10,
  FREEKOB: 99,
}

const Cart = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [qty, setQty] = useState({});
  const accessories = getAccessories()
  const [cartAccessories, setCartAccessories] = useState({})
  const [applyCoupon, setApplyCoupon] = useState(false)
  const [coupon, setCoupon] = useState("")
  const { data, onSubmit } = useAxios({
    url: apis.charges,
    method: "post"
  })

  useEffect(() => {
    onSubmit({
      status: "active"
    })
    // eslint-disable-next-line
  }, [])

  const cardsSum = useMemo(() => _.sum(location?.state?.selectedCard?.map(ele => (ele?.amount * (qty[ele._id] || 1)))), [qty, location])
  const accessoriesSum = useMemo(() => _.sum(Object.keys(cartAccessories)?.map(ele => (cartAccessories[ele]?.amount))), [cartAccessories])
  const discount = data?.data?.find(ele => ele.key === "discount")
  const delivery = data?.data?.find(ele => ele.key === "delivery_charges")
  const discPrice = useMemo(() => (cardsSum + accessoriesSum) - (discount?.amount ? parseFloat(calculateDiscount(cardsSum + accessoriesSum, discount?.amount).toFixed(2)) : 0), [discount, cardsSum, accessoriesSum, delivery])
  const price = useMemo(() => (applyCoupon ? discPrice - calculateDiscount(discPrice, coupons[coupon]) : discPrice), [applyCoupon, coupon, discPrice])

  const finalAmt = useMemo(() => data?.data?.length ? _.sumBy([
    ...data?.data?.filter(ele => ele.key !== "discount" && ele.key !== "delivery_charges")?.map(ele => ({
      amount: (ele.type === 1 ? parseFloat(calculateDiscount(price + delivery?.amount, ele.amount)?.toFixed(2)) : ele.amount) * (ele.isChargable ? 1 : -1)
    })), {
      amount: price + (delivery?.amount || 0)
    }
  ], "amount") : price + (delivery?.amount || 0), [price, cardsSum, accessoriesSum, applyCoupon, coupon])
  const actualAmt = cardsSum + accessoriesSum
  return (
    <div className='cart'>
      <div className='w60'>
        <h3>Shopping Cart</h3>
        <div className='hr-line'></div>
        {location?.state?.selectedCard?.map(ele => {
          return <div className='cart-details mt2 mb3'>
            <div className='image-sec'>
              <img src={badge} alt="" />
            </div>
            <div className='content-sec'>
              <div className='title'>
                <label>{ele?.name}</label>
                <img className='trash cursor' src={del} alt="" width={14} onClick={() => {
                  navigate("/cards")
                }} />
              </div>
              <p className='style'>Style - With Kobble Logo</p>
              <p className='price'>Rs. {ele?.amount}</p>
              <div className='quantity'>
                <button onClick={() => {
                  setQty(q => ({
                    ...q,
                    [ele._id]: q[ele._id] ? q[ele._id] - 1 : 1
                  }))
                }} disabled={!qty[ele._id] || qty[ele._id] <= 1}>-</button>
                <span>{qty[ele._id] || 1}</span>
                <button onClick={() => {
                  setQty(q => ({
                    ...q,
                    [ele._id]: q[ele._id] ? q[ele._id] + 1 : 2
                  }))
                }}>+</button>
              </div>
              <div>
                <p className='total'>
                  Total<br />
                  <strong>Rs. {ele?.amount * (qty[ele._id] || 1)}</strong>
                </p>
              </div>
            </div>
          </div>
        })}

        <div className='hr-line'></div>
        <h3>Add Ons</h3>
        <div className="flex-wrap mt1">
          {accessories?.map(ele => {
            return <div className="add-ons">
              <img src={ele.image_url} alt="" />
              <div className='quantity'>
                <button onClick={() => {
                  if (cartAccessories[ele._id]) {
                    setCartAccessories(access => ({
                      ...access,
                      [ele._id]: { image_url: ele.image_url, item_name: ele.name, amount: ele.price * (qty[ele._id] - 1), quantity: qty[ele._id] - 1 }
                    }))
                  }
                  setQty(q => ({
                    ...q,
                    [ele._id]: q[ele._id] ? q[ele._id] - 1 : 1
                  }))
                }} disabled={!qty[ele._id] || qty[ele._id] <= 1}>-</button>
                <span>{qty[ele._id] || 1}</span>
                <button onClick={() => {
                  if (cartAccessories[ele._id]) {
                    setCartAccessories(access => ({
                      ...access,
                      [ele._id]: { image_url: ele.image_url, item_name: ele.name, amount: ele.price * (qty[ele._id] ? 1 + qty[ele._id] : 2), quantity: qty[ele._id] ? 1 + qty[ele._id] : 2 }
                    }))
                  }
                  setQty(q => ({
                    ...q,
                    [ele._id]: q[ele._id] ? q[ele._id] + 1 : 2
                  }))
                }}>+</button>
              </div>
              <span className="price">
                Rs. {ele.price}
              </span>
              <button className="add-cart" onClick={() => {
                if (cartAccessories[ele._id]) {
                  let obj = { ...cartAccessories }
                  delete obj[ele._id]
                  setCartAccessories(obj)
                } else {
                  setCartAccessories(access => ({
                    ...access,
                    [ele._id]: { image_url: ele.image_url, item_name: ele.name, amount: ele.price * (qty[ele._id] || 1), quantity: qty[ele._id] || 1 }
                  }))
                }
              }}>{cartAccessories[ele._id] ? "Added" : "Add"} to Cart</button>
            </div>
          })}
        </div>
      </div>
      <div className='w40 subtotal'>
        <h3>Subtotal</h3>
        <div className='amts'>
          <p>
            <label>Amount</label>
            <span>Rs. {cardsSum}</span>
          </p>
          {Object.keys(cartAccessories)?.length > 0 && <p>
            <label>Add Ons</label>
            <span>Rs. {_.sumBy([
              ...Object.keys(cartAccessories)?.map(ele => ({
                amount: cartAccessories[ele]?.amount
              }))
            ], "amount")}</span>
          </p>}
          {discount && <p>
            <label>Discount ({discount?.amount}%)</label>
            <span>- Rs. {calculateDiscount(cardsSum + accessoriesSum, discount?.amount)?.toFixed(2)}</span>
          </p>}
          {applyCoupon && <p>
            <label>Coupon ({coupons[coupon]}%)</label>
            <span>- Rs. {calculateDiscount(discPrice, coupons[coupon])?.toFixed(2)}</span>
          </p>}
          {delivery && <p>
            <label>Delivery Charges </label>
            <span>Rs. {delivery.amount}</span>
          </p>}
          {data?.data?.filter(ele => ele.key !== "discount" && ele.key !== "delivery_charges")?.map(ele => (<p>
            <label>{ele.label} {ele.type === 1 ? `(${ele.amount}%)` : ""}</label>
            <span>Rs. {ele.type === 1 ? calculateDiscount(price + delivery?.amount, ele.amount)?.toFixed(2) : ele.amount}</span>
          </p>))}
          <p>
            <label>Total Amount</label>
            <span>Rs. {finalAmt?.toFixed(2)}</span>
          </p>
          <p className='apply'>
            <input placeholder='Apply Coupon Code' value={coupon} onChange={(e) => {
              if (applyCoupon) {
                setApplyCoupon(false)
              }
              setCoupon(e.target.value.toUpperCase())
            }} />
            <button className="cursor" onClick={() => {
              if (applyCoupon) {
                setCoupon("")
                setApplyCoupon(false)
              } else
                setApplyCoupon(coupons[coupon] !== undefined)
            }}>{applyCoupon ? "Remove" : "Apply"}</button>
          </p>
          {!applyCoupon &&
            <ul className="coupons">
              <li onClick={() => {
                setCoupon("VIPKOB");
                setApplyCoupon(true)
              }}>
                VIPKOB - 50%
                <span>Click here to Apply
                </span>
              </li>
            </ul>}
          <button className='proceed' onClick={() => {
            let obj = {
              actual_price: actualAmt,
              finalAmount: parseFloat(finalAmt)?.toFixed(2),
              card: location?.state?.selectedCard,
              qty,
              amount: discPrice,
              addOns: Object.keys(cartAccessories).length ? Object.values(cartAccessories) : [],
            }
            if (applyCoupon) {
              obj.coupon_code = coupon
              obj.coupon_amount = parseFloat(calculateDiscount(discPrice, coupons[coupon])?.toFixed(2))
            }
            if (isAddress()) {
              navigate("/thank-you", {
                state: obj
              })
            }
            else {
              navigate("/address", {
                state: obj
              })
            }
          }}>
            Proceed
          </button>
        </div>
      </div>
    </div >
  );
};

export default Cart;