import React from "react";

const SignUpCount = ({ count }) => {
    return <div className="SignUpSection isFlexColumn">
        <span className="total">Total</span>
        <span className="signUp">Sign up's</span>
        <span className="isCount">{count}</span>
    </div>
}

export default SignUpCount;