import React from 'react';
import { useNavigate } from 'react-router-dom';

const CorpAdminHeader = ({ label }) => {
  const navigate = useNavigate()
  return (
    <div className='isFlex alignCenter spacing'>
      <h1 className='title'>{label}</h1>
      <div className='isFlex alignCenter'>
        <span className='ml-2 iconFarward Cursor' onClick={() => {
          localStorage.clear()
          navigate("/")
        }}>Logout&nbsp;&nbsp;
          <img width={25} src={require("../../../assets/images/admin-dashboard/Logout.png")} alt="Arrow"></img>
        </span>
      </div>
    </div>
  );
};

export default CorpAdminHeader;