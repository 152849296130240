import React from 'react';
import join_kobble_left from '../../assets/join_kobble_left.png';

const Page5 = () => {
  return (
    <div className='page5'>
      <div className='w50 flex-center'>
        <img src={join_kobble_left} alt="" />
      </div>
      <div className='w50'>
        <div className='ml5'>
          <h3>Join Waitlist and Get KOBBLE.</h3>
          <p>Kobble is invite only. Sign up to join our waitlist.</p>
        </div>
        <form>
          <input placeholder='Name' />
          <input placeholder='Mobile' />
          <input placeholder='Email' />
          <button>Get Kobble</button>
        </form>
      </div>
    </div>
  );
};

export default Page5;