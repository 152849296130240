import React from "react";

const TransactionsGraph = ({ label }) => {
    return <div className="noOfTxnGraph">
        <div className="isCountSection isFlex alignCenter spacing">
            <div className="isFlex alignCenter">
                <div>
                    <img width={60} src={require("../../../assets/images/admin-dashboard/Transactions.png")} alt="Number of Transactions"></img>
                </div>
                <div className="isFlexColumn">
                    <span className="isLabel">{label}</span>
                    <span className="isCount">0</span>
                </div>
            </div>
            <div>
                <img className="iconAnimee" width={34} src={require("../../../assets/images/admin-dashboard/Growth.png")} alt="Growth"></img>
            </div>
        </div>
    </div>
}

export default TransactionsGraph;