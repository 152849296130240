import React, { useEffect } from "react";
import { apis } from "../../utils/apis";
import { get_profile } from "../../utils/helper";
import useAxios from "../../utils/useAxios";
import Datatable from "./features/Datatable";

const EmployeeData = () => {
	const { loading, onSubmit: get_users, data: users } = useAxios({
		url: apis.users + "/1/100",
		method: "post",
	})

	useEffect(() => {
		get_users({
			code: get_profile("code")
		})
	}, [])

	return <>
		<div className="px-25 isFlex alignCenter spacing">
			<h1 className="title">Total Employees - {users?.count}</h1>
			<div className="isTotalEmployees">
				<span className="searchByUser">
					<input type="text" className="formInput inputShadow" placeholder="Search user" />
					<img src={require("../../assets/images/Icon-search.svg").default} width="16" alt="Search" />
				</span>
				<span className="viewType ml-4">
					<span className="isTableview mr-2 pr-2">
						<img className="Cursor" src={require("../../assets/images/Table-view.svg").default} width="22" alt="Table" />
					</span>
					<span className="isGridview">
						<img className="Cursor" src={require("../../assets/images/Grid-view.svg").default} width="22" alt="Grid" />
					</span>
				</span>
			</div>
		</div>
		<div className="px-25 mt-5">
			<Datatable data={users?.data} loading={loading} totalCount={users?.count} />
		</div>
	</>
}

export default EmployeeData;