import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { apis } from '../../utils/apis';
import endpoint from '../../utils/endpoint';
import useAxios from '../../utils/useAxios';

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

const Qr_NFC = ({ isNFC }) => {
  const params = useParams()
  const [profileData, setProfileData] = useState({})
  const [savingContact, setSavingContact] = useState(false)
  const { loading, onSubmit, data } = useAxios({
    url: apis.connection_add,
    method: "post"
  })

  useEffect(() => {
    if (params?.profileId) {
      endpoint.get("/qrcode/" + params?.profileId).then(res => {
        if (res?.data?.data)
          setProfileData(res?.data?.data)
        else
          setProfileData(res?.data)

      }).catch(err => {
        setProfileData({
          errors: err?.response?.data,
          error: false
        })
      })
    }
    // eslint-disable-next-line
  }, [])

  const generateVCard = () => {
    setSavingContact(true)
    toDataUrl(profileData.profile_url, function (myBase64) {

      const vCard =
        `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${profileData?.firstname} ${profileData?.lastname}
N;CHARSET=UTF-8:${profileData?.lastname};${profileData?.firstname};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${profileData?.email}
TEL;TYPE=CELL:${profileData?.mobile}
PHOTO;ENCODING=b;TYPE=PNG:${myBase64?.replace("data:image/jpeg;base64,", "")}
REV:${new Date().toISOString()}
END:VCARD
`
      const blob = new Blob([vCard], { type: 'text/plain' });
      saveAs(blob, `${profileData?.firstname}_${profileData?.lastname}.vcf`);
      setSavingContact(false)
    });

  }

  return (
    <>
      <div className='page-1'>
        {profileData?.email || profileData?.mobile ?
          <div className='bg-card-center'>
            <img src={profileData?.profile_url?.replace("https://kobble.in", "https://kobble.co.in")} className="profile-pic" id="profile-pic" alt='' />
            <p>{profileData?.firstname} {profileData?.lastname}</p>
            <p className='desc'>{profileData?.description}</p>
            {profileData?.socialMedia?.length ? <div className='social-icons'>{profileData?.socialMedia?.map(itm =>
              <a href={itm.name === "Phone" ? "tel:" + itm.prefix : itm.name === "Gmail" ? "mail:" + itm.prefix : itm.prefix} target="_blank" style={{
                color: "white",
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}>
                <span style={{
                  background: "#34554A",
                  borderRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 10,
                  width: 50,
                  height: 50
                }}>
                  <img src={itm?.image_url?.replace("https://kobble.in", "https://kobble.co.in")} alt='' width={30} />
                </span>
                <span>
                  {itm.name}
                </span>
              </a>
            )}</div> : null}
            <button className='contact-btn' onClick={generateVCard} disabled={!profileData.email || savingContact}>
              {savingContact ? "Saving" : "Save"} Contact
            </button>
          </div> :
          <div className='bg-card-center'>
            <p className='mb1'>Seems this QR/NFC not linked to user.</p>
            <button className='contact-btn' onClick={() => {
              window.open(`https://play.google.com/store/apps/details?id=com.app.kobble&code=${params?.profileId}`, "_target")
            }}>
              Activate Now
            </button>
          </div>
        }
      </div>
    </>
  );
};

export default Qr_NFC;