import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { apis } from "../../utils/apis";
import useAxios from "../../utils/useAxios";
import "./CorporateCard.scss";

const CorporateCard = () => {
  const params = useParams()
  const location = useLocation()
  const { loading, onSubmit, data } = useAxios({
    url: apis.corporate_user_registers,
    method: "post"
  })
  const formik = useFormik({
    initialValues: {
      "firstname": "",
      "lastname": "",
      "email": "",
      "country": "+91",
      "mobile": "",
      "designation": "",
      "department": "",
      "code": location?.state?.code || params?.code
    },
    onSubmit: (vals) => {
      onSubmit(vals)
    }
  })

  useEffect(() => {
    if (data?.success) {
      formik.resetForm()
    }
    // eslint-disable-next-line
  }, [data])
  return <div className="isCoporateCardContainer">
    <div className="isCorporateWrapper isFlex">
      <div className="cardCreationWrapper">
        <div className="isCardBackground">
          <div className="isCorporateCard">
            <div className="isCard">
              <img className="isCardLogo" src={require("../../assets/images/corporate-card/card.svg").default} alt="logo"></img>
              <div className="imagePreview isFlex alignCenter">
                <img width="40" className="Cursor" src={require("../../assets/images/corporate-card/Refresh.png")} alt="corporate"></img>
                <span className="imagePreviewtxt pl-2">kobble card image preview.</span>
              </div>
            </div>
          </div>

        </div>

        <img className="isLogo" width="36" src={require("../../assets/images/KOBBLE.svg").default} alt="logo"></img>
      </div>
      <div className="cardCreationDetails p-5 isFlex alingCenter justifyCenter">
        <div className="isFlexColumn alignCenter justifyCenter w-100">
          <img width="60" src={require("../../assets/images/corporate-card/corporate.png")} alt="corporate"></img>
          <h2 className="title mt-3">Kobble Corporate Card</h2>
          <div className="subTitle mt-1">Please enter the details.</div>
          <div className="cardCreationForm mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="formControl">
                <label>First Name</label>
                <div className="isInputGroup">
                  <input className="isInputHover" value={formik.values.firstname} name="firstname" onChange={formik.handleChange} type="text" placeholder="Enter name"></input>
                  <span>
                    <img width="20" src={require("../../assets/images/corporate-card/user.png")} alt="user"></img>
                  </span>
                </div>
              </div>
              <div className="formControl">
                <label>Last Name</label>
                <div className="isInputGroup">
                  <input className="isInputHover" value={formik.values.lastname} name="lastname" onChange={formik.handleChange} type="text" placeholder="Enter name"></input>
                  <span>
                    <img width="20" src={require("../../assets/images/corporate-card/user.png")} alt="user"></img>
                  </span>
                </div>
              </div>
              <div className="formControl">
                <label>Mobile</label>
                <div className="isInputGroup">
                  <input className="isInputHover" name="mobile" onChange={(e) => {
                    if (new RegExp(/[0-9]/).test(e.target.value) || e.target.value === "") {
                      formik.setFieldValue("mobile", e.target.value)
                    }
                  }} type="text" value={formik.values.mobile} placeholder="Enter Mobile number"></input>
                  <span>
                    <img width="18" src={require("../../assets/images/corporate-card/mobile.png")} alt="mobile"></img>
                  </span>
                </div>
              </div>
              <div className="formControl">
                <label>Email</label>
                <div className="isInputGroup">
                  <input className="isInputHover" type="email" value={formik.values.email} onChange={formik.handleChange} name="email" placeholder="Enter Email ID"></input>
                  <span>
                    <img width="20" src={require("../../assets/images/corporate-card/email.png")} alt="email"></img>
                  </span>
                </div>
              </div>
              <div className="formControl">
                <label>Designation</label>
                <div className="isInputGroup">
                  <input className="isInputHover noPad" value={formik.values.designation} name="designation" onChange={formik.handleChange} type="text" placeholder="Enter designation"></input>
                </div>
              </div>
              <div className="formControl">
                <label>Department</label>
                <div className="isInputGroup">
                  <input className="isInputHover noPad" value={formik.values.department} name="department" onChange={formik.handleChange} type="text" placeholder="Enter department"></input>
                </div>
              </div>
              <div className="isCardButton mt-3">
                <button className="btn w-100 isSubmitBtn" disabled={loading} type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default CorporateCard;