import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apis } from "../../utils/apis";
import { order_status } from "../../utils/helper";
import useAxios from "../../utils/useAxios";
import Dispatch from "./features/Dispatch";
import Delivered from "./features/Dispatched";
import OrdersTable from './features/OrdersTable';
import Production from "./features/Production";
import Transit from "./features/Transit";

const OrdersContainer = () => {
  const params = useParams()
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 20
  })
  const { loading, onSubmit: getOrders, data: orders, reFetch } = useAxios({
    url: apis.dashboard_orders + `/${filters.page}/${filters.pageSize}`,
    method: "post"
  })

  useEffect(() => {
    getOrders({
      statusFilter: params?.status === "production" ? order_status.production :
        params?.status === "dispatch" ? order_status.dispatch :
          params?.status === "transit" ? order_status.transit :
            params?.status === "delivered" ? order_status.delivered :
              order_status.received
    })
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    if (filters?.fromDateFilter || filters?.ordNumFilter)
      getOrders({
        ...filters,
        statusFilter: params?.status === "production" ? order_status.production :
          params?.status === "dispatch" ? order_status.dispatch :
            params?.status === "transit" ? order_status.transit :
              params?.status === "delivered" ? order_status.delivered :
                order_status.received
      })
  }, [filters])

  return <div className="isOrdersContainer px-25 py-4">
    <OrdersTable data={orders?.data} loading={loading} setFilters={setFilters} refetch={() => {
      getOrders({
        statusFilter: order_status.received
      })
    }} />
  </div>
}

export default OrdersContainer;