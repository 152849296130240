import React, { useEffect } from 'react';
import Country from '../form-components/Country';
import Input from '../form-components/Input';
import addr_bg from '../../assets/address_bg.svg'
import { useFormik } from 'formik';
import { apis } from '../../utils/apis';
import useAxios from '../../utils/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import { getId } from '../../utils/helper';
import { ScaleLoader } from 'react-spinners';
const Address = () => {
  const { loading, onSubmit, data } = useAxios({
    url: apis.update_address,
    method: "post"
  })
  const location = useLocation();
  const { loading: addrLoading, onSubmit: getAddr, data: addrData } = useAxios({
    url: apis.fetch_address,
    method: "post",
    msg: true
  })
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      "firstname": "",
      "lastname": "",
      "country": "+91",
      "email": "",
      "address1": "",
      "address2": "",
      "city": "",
      "state": "",
      "isDefault": true,
      "addressType": ""

    },
    onSubmit: (vals) => {
      onSubmit({ ...vals, fk_userId: getId() })
    }
  })

  useEffect(() => {
    if (addrData?.success && addrData?.address?.length > 0 && location.state && location.state.finalAmount) {
      navigate("/thank-you", {
        state: location.state
      })
    }
    // eslint-disable-next-line
  }, [addrData, location.state])

  useEffect(() => {
    if (data?.success && location.state && location.state.finalAmount) {
      navigate("/thank-you", {
        state: location.state
      })
    }
    // eslint-disable-next-line
  }, [data, location.state])

  useEffect(() => {
    getAddr({
      _id: getId()
    })
    // eslint-disable-next-line
  }, [])
  return (addrLoading && location.state?.finalAmount ? <div className='flex-center'>
    <ScaleLoader color='#0f1010' />
  </div> : (
    <div className='address-form'>
      <img src={addr_bg} alt="" className='addr-bg' />
      <div className='flex-between'>
        <h6>Add a New Address</h6>
        <span className='home' onClick={() => {
          navigate("/")
        }}>Home</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='d-flex gap2 mt1'>
          <Country label={"Country / Region"} disabled={true} />
          <Input formik={formik} label={"Full name"} name="firstname" />
        </div>
        <div className='d-flex gap2 mt1'>
          <Input formik={formik} label={"Mobile Number"} name="mobile" />
          <Input formik={formik} label={"Pincode"} name="pincode" />
        </div>
        <div className='d-flex gap2 mt1'>
          <Input formik={formik} label={"Email"} name="email" />
          <Input formik={formik} label={"Flat, House no, Building , Company, Apartment"} name="address1" />
        </div>
        <div className='d-flex gap2 mt1'>
          <Input formik={formik} label={"Area, Sector, village"} name="address2" />
          <Input formik={formik} label={"Landmark"} name="Landmark" />
        </div>
        <div className='d-flex gap2 mt1'>
          <Input formik={formik} label={"City"} name="city" />
          <Input formik={formik} label={"State"} name="state" />
        </div>
        <div className='p-relative mt1 default-addr'>
          <input type={"checkbox"} hidden={true} id="input_check" name="isDefault" value={formik.values.isDefault} onChange={(e) => {
            formik.setFieldValue("isDefault", e.target.checked)
          }} />
          <span className="checkmark" onClick={() => {
            document.getElementById("input_check").click()
          }}></span>
          <label className='ml1'>Make this my default address.</label>
        </div>
        <div className='delivery-instructions mt1'>
          <label>Add delivery instructions</label>
          <p className='note mtHalf w30'>
            Preferences are used to plan your delivery. However, shipments can sometimes arrive early or later than planned.
          </p>
          {/* <textarea rows={5} placeholder='Preferences are used to plan your delivery. However, shipments can sometimes arrive early or later than planned.' /> */}
        </div>
        <button className='save-btn mt1 cursor' disabled={loading}>Save Address</button>
      </form>
    </div>
  )
  );
};

export default Address;