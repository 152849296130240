import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { order_status } from "../../../utils/helper";
import useAxios from "../../../utils/useAxios";
import CommonDataTable from './CommonDataTable';
import CommonOrderHeader from "./CommonOrderHeader";
import SuperAdminHeader from "./SuperAdminHeader";

const OrdersTable = ({ data, loading, refetch, setFilters }) => {
  const { loading: ordersStatusLoading, onSubmit: ordersStatusCall, data: orderStatusRes, reset } = useAxios({
    url: apis.orders_status_update,
    method: "post"
  })
  const params = useParams()
  const [selectedIds, setSelectedIds] = useState([])
  
  useEffect(() => {
    if (orderStatusRes?.success) {
      reset()
      setSelectedIds([])
      refetch()
    }
    // eslint-disable-next-line
  }, [orderStatusRes])

  const labels = {
    delivered: "Delivered",
    production: "Production",
    dispatch: "Dispatch",
    transit: "Transit",
  }

  return <>
    <SuperAdminHeader isDateFilter={true} label={labels[params?.status] || "Orders"} setFilters={setFilters} key={"ordNumFilter"} />
    <CommonOrderHeader data={data} />
    <div className="isCommonDataTable mt-3">
      <CommonDataTable noCheck={params?.status === "delivered"} data={data} loading={loading} setSelectedIds={setSelectedIds} selectedIds={selectedIds} />
    </div>
    <div className="footerNote isFlex alignCenter spacing mt-4">
      <span>
        Note : Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
      </span>
      {!params?.status && <button className="btn btn-dark" disabled={selectedIds.length === 0 || ordersStatusLoading} onClick={() => {
        ordersStatusCall({
          status: selectedIds.map(ele => ({
            _id: ele,
            order_status: order_status.production
          }))
        })
      }}>Move to Production <img className="ml-3" height={14} src={require("../../../assets/images/admin-dashboard/Right_arrow.png")} alt="arrow"></img></button>}
      {params?.status === "production" && <button className="btn btn-dark" disabled={selectedIds.length === 0 || ordersStatusLoading} onClick={() => {
        ordersStatusCall({
          status: selectedIds.map(ele => ({
            _id: ele,
            order_status: order_status.dispatch
          }))
        })
      }}>Move to Dispatch <img className="ml-3" height={14} src={require("../../../assets/images/admin-dashboard/Right_arrow.png")} alt="arrow"></img></button>}
      {params?.status === "dispatch" && <button className="btn btn-dark" disabled={selectedIds.length === 0 || ordersStatusLoading} onClick={() => {
        ordersStatusCall({
          status: selectedIds.map(ele => ({
            _id: ele,
            order_status: order_status.transit
          }))
        })
      }}>Move to Transit <img className="ml-3" height={14} src={require("../../../assets/images/admin-dashboard/Right_arrow.png")} alt="arrow"></img></button>}
      {params?.status === "transit" && <button className="btn btn-dark" disabled={selectedIds.length === 0 || ordersStatusLoading} onClick={() => {
        ordersStatusCall({
          status: selectedIds.map(ele => ({
            _id: ele,
            order_status: order_status.delivered
          }))
        })
      }}>Move to Delivered <img className="ml-3" height={14} src={require("../../../assets/images/admin-dashboard/Right_arrow.png")} alt="arrow"></img></button>}

    </div>
  </>
}

export default OrdersTable;