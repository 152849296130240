import React from 'react';
const Input = ({ label, name, formik }) => {
  return (
    <div className='input-wrap'>
      <input name={name} onChange={formik?.handleChange} />
      <label>{label}</label>
    </div>
  );
};

export default Input;