import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import check from "../../assets/checkmark.svg";
import App from "../../assets/App.png";
import AppQR from "../../assets/kobble_download_link.png";
import { apis } from '../../utils/apis';
import endpoint from '../../utils/endpoint';
import { getId, getMobile } from '../../utils/helper';
import useAxios from '../../utils/useAxios';
const ThankYou = () => {
  const navigate = useNavigate()
  const { onSubmit: getAddr, data: addrData } = useAxios({
    url: apis.fetch_address,
    method: "post",
    msg: true
  })

  const { data, onSubmit } = useAxios({
    url: apis.charges,
    method: "post"
  })
  const [loading, setLoading] = useState(false)
  const [payDone, setPayDone] = useState(false)
  useEffect(() => {

    // eslint-disable-next-line
  }, [])
  const location = useLocation();
  const [selectedId, setSelectedId] = useState(null)
  useEffect(() => {
    getAddr({
      _id: getId()
    })
    onSubmit({
      status: "active"
    })
    if (location.state?.payment_done)
      setPayDone(location.state?.payment_done)
    // eslint-disable-next-line
  }, [])

  return (
    <div className='thank_you'>
      <div className='w50 left'>
        <h3>Thanks for Shopping !</h3>
      </div>
      <div className='w35 right'>
        {payDone ? <div>
          <div className='title'>
            <label>Payment Successfull. Your kobble kit will be delivered soon.
              <span className='link ml1' onClick={() => {
                navigate("/")
                sessionStorage.clear()
              }}>Go Back to Home</span>
            </label>
          </div>
          <h3 style={{
            textAlign: "center",
            fontSize: 20,
            margin: "20px 0"
          }}>Download Our App and Get Started.</h3>
          <div className='flex-between mt3'>
            <div className='flex-center flex-col'>
              <img src={AppQR} alt="" width={200} className="mb2" />
              <a href="https://play.google.com/store/apps/details?id=com.app.kobble" target="_blank" rel="noopener noreferrer"><img className="_12dJy" height="54" alt='' src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_108/play_ip0jfp" /></a>
              {/* <a href="https://itunes.apple.com/in/app/swiggy-food-order-delivery/id989540920" target="_blank" rel="noopener noreferrer"><img class="_37Cft" height="54" src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_108/iOS_ajgrty" /></a> */}
            </div>
            {/* <div>
              <img src={App} alt="" width={400} />
            </div> */}
          </div>
        </div>
          : <>

            <div className='title del'>
              <label>Select Delivery Address</label>
              <button onClick={() => {
                navigate("/address")
              }}>Add New</button>
            </div>
            <ul>
              {addrData?.address?.map(ele => <li className={`p-relative address ${selectedId?._id === ele._id ? "selected" : ""}`}
                onClick={() => {
                  setSelectedId(ele)
                }}
              >
                <p className='name'>
                  {ele.firstname}
                  {selectedId?._id === ele._id && <img src={check} alt="" width={20} style={{
                    position: "absolute",
                    top: 10,
                    right: 10
                  }} />}
                </p>
                <p className='addr'>
                  {ele.address1}, {ele.address2}, {ele.city}, {ele.state}, {ele.pincode}
                </p>
                <p className='mobile'>
                  Mobile - <strong>
                    {ele.mobile}
                  </strong>
                </p>
              </li>)}
            </ul>
            <div>
              <button className='deliver' disabled={!selectedId || loading} onClick={() => {
                setLoading(true)
                let obj = {}
                data?.data?.forEach(element => {
                  obj[element.key] = element.amount
                  obj[element.key + "_type"] = element.type
                });
                if (location.state?.coupon_code) {
                  obj.coupon_code = location.state?.coupon_code
                  obj.coupon_amount = location.state?.coupon_amount
                }
                endpoint.post(apis.create_order, {
                  "fk_userId": getId(),
                  "products": location.state?.card?.map(ele => ({
                    "item_code": ele?.code,
                    "item_name": ele?.name,
                    "amount": ele?.amount,
                    "quantity": location.state?.qty[ele._id],
                    "image_url": ele?.image_url,
                    // "addOns": location.state?.addOns
                  })),
                  "actual_price": location.state?.actual_price,
                  "currency": "INR",
                  ...obj,
                  "total": location.state?.finalAmount,
                  "delivery_address_id": selectedId?._id,
                  "accessories": location.state?.addOns
                }).then(res => {
                  if (res?.data?.success) {
                    let options = {
                      key: "rzp_live_ATUT38IOn2RxEC",
                      image: 'https://i.imgur.com/n5tjHFD.png',
                      order_id: res?.data?.order?.id,
                      amount: res?.data?.order?.amount * 100,
                      currency: "INR",
                      prefill: {
                        "email": selectedId?.email,
                        "contact": getMobile()
                      },
                      handler: function (response) {
                        if (!response.error) {
                          endpoint.post(apis.payment_update, {
                            "order_ref_id": res?.data?.order?.id,
                            "currency": "INR",
                            "amount_paid": res?.data?.order?.amount,
                            "status": "success",
                            ...response
                          })
                          setPayDone(true)
                        }
                        setLoading(false)
                      },
                    }
                    let rzPay = new window.Razorpay(options)
                    rzPay.open()

                    rzPay.on('payment.error', function (resp) {
                      console.log({
                        "order_ref_id": res?.data?.order?.id,
                        "currency": "INR",
                        "amount_paid": res?.data?.order?.amount,
                        "status": "failed",
                        ...resp
                      });
                      endpoint.post(apis.payment_update, {
                        "order_ref_id": res?.data?.order?.id,
                        "currency": "INR",
                        "amount_paid": res?.data?.order?.amount,
                        "status": "failed",
                        "description": resp.error.description,
                      })
                      setLoading(false)
                    });
                  } else {
                    setLoading(false)
                  }
                }).catch(err => {
                  setLoading(false)
                })
              }}>Deliver Here</button>
            </div>
          </>}
      </div>
    </div>
  );
};

export default ThankYou;