import moment from "moment";
import React, { useEffect } from "react";
import { apis } from "../../../utils/apis";
import useAxios from "../../../utils/useAxios";

const DashboardTable = () => {
  const { loading, onSubmit: getOrders, data: orders } = useAxios({
    url: apis.dashboard_orders + "/1/5",
    method: "post"
  })

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line
  }, [])
  return <div className="isOrdersDataTable">
    <div className="isFlex alignCenter isOrdersTitle">
      <img className="mr-2" width={50} src={require("../../../assets/images/admin-dashboard/Orders_Recevied.png")} alt="Orders Recevied"></img>
      <h3 className="title">Orders</h3>
    </div>
    <table className="w-100">
      <thead>
        <tr>
          <th></th>
          <th>ORDER ID</th>
          <th>CUSTOMER</th>
          <th>DATE</th>
          <th>AMOUNT</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        {loading ? <tr><td colSpan={6} style={{ textAlign: "center" }}>
          Loading
        </td></tr> :
          orders?.data?.length > 0 ? orders?.data?.map((ele, idx) => {
            return <tr>
              <td>{idx + 1}.</td>
              <td>{ele.order_ref_number}</td>
              <td>{ele.fk_userId?.firstname}</td>
              <td>
                {ele.createdDate && moment(ele.createdDate).format("DD MMM YYYY")}
              </td>
              <td>{ele.total && `Rs. ${0}`}</td>
              <td>
                <button className="btn btn-success">{ele.order_status}</button>
              </td>
            </tr>
          }) : <tr><td colSpan={6} style={{ textAlign: "center" }}>
            No Data
          </td></tr>}
      </tbody>
    </table>
  </div>
}

export default DashboardTable;