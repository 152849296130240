import React from 'react';
import Select from 'react-select';
import { useCountries } from '../../utils/useCountries';

const Country = ({ label, dark, disabled }) => {

  const [countries] = useCountries()
  return (
    <div className='input-select'>
      <Select className='country' defaultValue={{ "label": "India", "value": "IN", image: "https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/in.svg" }} styles={{
        control: (styles) => (dark ? {
          ...styles,
          border: "none",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          height: "4rem",
          display: "flex",
          flexWrap: "wrap",
          backgroundColor: "#0F1010",
          // ":hover": { backgroundColor: "#0F1010" },
          // ":focus-within": { backgroundColor: "#0F1010" },
        } : {
          ...styles,
          backgroundColor: " #f0f0f0",
          border: "none",
          borderRadius: 8,
          height: "3rem",
          display: "flex",
          flexWrap: "wrap",
        }),
        option: (styles, state) => {
          return dark ? {
            ...styles,
            cursor: "pointer",
            backgroundColor: state.isFocused ? "#9D9F9E" : "#0F1010",
          } : {
            ...styles
          }
        },
        indicatorSeparator: () => ({
          display: "none"
        })
      }}
        isDisabled={disabled}
        options={countries} getOptionLabel={(option) => <label className='flag-name'><img src={option.image} width={20} height={20} className="mr1" alt='' /> {option.label}</label>} />
      <label>{label}</label>
    </div>
  );
};

export default Country;