import React from 'react';
import share from "../../assets/share_tap.svg"
const Page4 = () => {
  return (
    <div className='page4'>
      <div className='w50 flex-center'>
        <img src={share} alt="" />
      </div>
      <div className='w50'>
        <div className='ml5'>
          <h3>Share it with a Tap.</h3>
          <p>Card can be tapped on most of the Android phones and newer iPhones. Non-NFC phones can scan the QR code to access your profile. No apps required.</p>
        </div>
      </div>
    </div>
  );
};

export default Page4;