import React from 'react';
import page3_left from "../../assets/page3_left.png"
import page3_right from "../../assets/page3_right.png"

const Page3 = () => {
  return (
    <div className='page3'>
      <img className="w50 left" src={page3_left} alt="" />
      <div className="w50">
        <img className="w100" src={page3_right} alt="" />
        <h3>Card for the Future.</h3>
      </div>
    </div>
  );
};

export default Page3;