import React from "react";

const Card = ({ label, img, data }) => {
  return <div className="isCard">
    <div className="isFlexColumn">
      <div className="isCountSection isFlex alignCenter">
        <div>
          <img width={60} src={img} alt={"Number of " + label}></img>
        </div>
        <div className="isFlexColumn">
          <span className="isLabel">Number of {label}</span>
          <span className="isCount">{data?.totalUsers || data?.totalOrders || 0}</span>
        </div>
      </div>
      <div className="isToggleSection isFlex noWrap">
        <div className="isToggleButton isFlexColumn alignCenter justifyCenter active">
          <img width={20} src={require("../../../assets/images/admin-dashboard/Indivadual_Active.png")} alt="Number of customers"></img>
          <span className="isLabel">Individual</span>
          <span className="isCount">{data?.onlyNormal || data?.normalOrders || 0}</span>
        </div>
        <div className="isToggleButton isFlexColumn alignCenter justifyCenter">
          <img width={20} src={require("../../../assets/images/admin-dashboard/Corporate_Inactive.png")} alt="Corporate"></img>
          <span className="isLabel">Corporate</span>
          <span className="isCount">{data?.onlyCorpUsers || data?.corporateOrders || 0}</span>
        </div>
        <div className="isToggleButton isFlexColumn alignCenter justifyCenter">
          <img width={20} src={require("../../../assets/images/admin-dashboard/Premium_Inactive.png")} alt="Premium"></img>
          <span className="isLabel">Premium</span>
          <span className="isCount">{data?.corpAndNormalUsers || data?.corpAndNormalOrders || 0}</span>
        </div>
      </div>
    </div>
  </div>
}

export default Card;