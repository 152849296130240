import React from "react";
import EmployeeDetailsHeader from "./features/EmployeeDetailsHeader";
// import Datatable from "./features/Datatable";
import { Outlet } from "react-router-dom";
import { get_profile } from "../../utils/helper";
import CorpAdminHeader from "../super-admin/features/CorpAdminHeader";

const EmployeeDesignationList = () => {
  return <div className="py-4">
    <div className="isEmployeeDataContainer borderBottom mb-3 pb-3">
      <div className="px-25">
        <CorpAdminHeader label={`Kobble Link (for ${get_profile("noOfEmps")} Cards)`} />
        <div className="isEmployeeDetails">
          <EmployeeDetailsHeader />
        </div>
      </div>
    </div>
    <Outlet />
  </div>
}

export default EmployeeDesignationList;