import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { apis } from '../../utils/apis';
import endpoint from '../../utils/endpoint';
import useAxios from '../../utils/useAxios';

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

const ShareProfile = ({ isShare }) => {
  const params = useParams()
  const [profileData, setProfileData] = useState({})
  const [savingContact, setSavingContact] = useState(false)
  const [isShareModal, setIsShareModal] = useState(isShare)
  const { loading, onSubmit, data } = useAxios({
    url: apis.connection_add,
    method: "post"
  })
  const formik = useFormik({
    initialValues: {
      "firstname": "",
      "lastname": "",
      "email": "",
      "country": "+91",
      "mobile": "",
    },
    onSubmit: (vals) => {
      // onSubmit(vals)
      vals.fk_userId = profileData?._id
      onSubmit(vals)
    }
  })

  useEffect(() => {
    if (data?.success) {
      formik.resetForm()
    }
    // eslint-disable-next-line
  }, [data])
  useEffect(() => {
    if (params?.profileId) {
      endpoint.get("/qrcode/" + params?.profileId).then(res => {
        if (res?.data?.data)
          setProfileData(res?.data?.data)
        else
          setProfileData(res?.data)

      }).catch(err => {
        setProfileData({
          errors: err?.response?.data,
          error: false
        })
      })
    }
    // eslint-disable-next-line
  }, [])

  const generateVCard = () => {
    setSavingContact(true)
    toDataUrl(profileData.profile_url, function (myBase64) {

      const vCard =
        `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${profileData?.firstname} ${profileData?.lastname}
N;CHARSET=UTF-8:${profileData?.lastname};${profileData?.firstname};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${profileData?.email}
TEL;TYPE=CELL:${profileData?.mobile}
PHOTO;ENCODING=b;TYPE=PNG:${myBase64?.replace("data:image/jpeg;base64,", "")}
REV:${new Date().toISOString()}
END:VCARD
`
      const blob = new Blob([vCard], { type: 'text/plain' });
      saveAs(blob, `${profileData?.firstname}_${profileData?.lastname}.vcf`);
      setSavingContact(false)
    });

  }

  return (
    <>
      <div className='page-1'>
        {profileData?.email || profileData?.mobile ?
          <div className='bg-card-center'>
            <img src={profileData?.profile_url?.replace("https://kobble.in", "https://kobble.co.in")} className="profile-pic" id="profile-pic" alt='' />
            <p>{profileData?.firstname} {profileData?.lastname}</p>
            <p className='desc'>{profileData?.description}</p>
            {profileData?.socialMedia?.length ? <div className='social-icons'>{profileData?.socialMedia?.map(itm =>
              <a href={itm.name === "Phone" ? "tel:" + itm.prefix : itm.name === "Gmail" ? "mail:" + itm.prefix : itm.prefix} target="_blank" style={{
                color: "white",
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}>
                <span style={{
                  background: "#34554A",
                  borderRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 10,
                  width: 50,
                  height: 50
                }}>
                  <img src={itm?.image_url?.replace("https://kobble.in", "https://kobble.co.in")} alt='' width={30} />
                </span>
                <span>
                  {itm.name}
                </span>
              </a>
            )}</div> : null}
            <button className='contact-btn' onClick={generateVCard} disabled={!profileData.email || savingContact}>
              {savingContact ? "Saving" : "Save"} Contact
            </button>
          </div> :
          <div className='bg-card-center'>
            <p>{profileData?.msg}</p>
          </div>
        }
      </div>
      <Popup open={isShareModal} className="share" onClose={() => setIsShareModal(false)} overlayStyle={{
        background: "rgba(0,0,0,0.4)"
      }} >

        <div className="cardCreationDetails p-3 isFlex alingCenter justifyCenter">
          <div className="isFlexColumn alignCenter justifyCenter w-100">
            <img width="60" src={require("../../assets/images/corporate-card/corporate.png")} alt="corporate"></img>
            <div className="subTitle mt-1">Please enter the details.</div>
            <div className="cardCreationForm mt-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="formControl">
                  <label>First Name</label>
                  <div className="isInputGroup">
                    <input className="isInputHover" value={formik.values.firstname} name="firstname" onChange={formik.handleChange} type="text" placeholder="Enter name"></input>
                    <span>
                      <img width="20" src={require("../../assets/images/corporate-card/user.png")} alt="user"></img>
                    </span>
                  </div>
                </div>
                <div className="formControl">
                  <label>Last Name</label>
                  <div className="isInputGroup">
                    <input className="isInputHover" value={formik.values.lastname} name="lastname" onChange={formik.handleChange} type="text" placeholder="Enter name"></input>
                    <span>
                      <img width="20" src={require("../../assets/images/corporate-card/user.png")} alt="user"></img>
                    </span>
                  </div>
                </div>
                <div className="formControl">
                  <label>Mobile</label>
                  <div className="isInputGroup">
                    <input className="isInputHover" name="mobile" onChange={(e) => {
                      if (new RegExp(/[0-9]/).test(e.target.value) || e.target.value === "") {
                        formik.setFieldValue("mobile", e.target.value)
                      }
                    }} type="text" value={formik.values.mobile} placeholder="Enter Mobile number"></input>
                    <span>
                      <img width="18" src={require("../../assets/images/corporate-card/mobile.png")} alt="mobile"></img>
                    </span>
                  </div>
                </div>
                <div className="formControl">
                  <label>Email</label>
                  <div className="isInputGroup">
                    <input className="isInputHover" type="email" value={formik.values.email} onChange={formik.handleChange} name="email" placeholder="Enter Email ID"></input>
                    <span>
                      <img width="20" src={require("../../assets/images/corporate-card/email.png")} alt="email"></img>
                    </span>
                  </div>
                </div>
                <div className="isCardButton mt-3">
                  <button className="btn w-100 isSubmitBtn" disabled={loading} type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ShareProfile;