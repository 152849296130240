import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import arrow from "../../assets/arrow-up-right.svg";
import login_lady from "../../assets/login_lady.png";
import { apis } from '../../utils/apis';
import useAxios from '../../utils/useAxios';
import Header from '../website/Header';

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required")
})

const CorporateLogin = ({ isSuperAdmin }) => {
  const navigate = useNavigate();
  const { loading, onSubmit, data } = useAxios({
    url: apis.corporate_login,
    method: "post"
  })
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (vals) => {
      onSubmit(vals)
    },
    validationSchema: validationSchema
  })

  useEffect(() => {
    if (data?.success) {
      // toast.success("Added corporate user.")
      formik.resetForm()
      if (isSuperAdmin) {
        localStorage.setItem("profile", JSON.stringify(data?.data))
        navigate("/super-admin")
      } else {
        localStorage.setItem("profile", JSON.stringify(data?.data))
        navigate("/corporate-admin")
      }
    }
    // eslint-disable-next-line
  }, [data])
  return (
    <div className='login-page corporate'>
      <Header onlyLogo={true} />
      <div className='flex-between mt4'>
        <img className='login_lady' src={login_lady} alt="" />
        <form className='w40 login-form' onSubmit={formik.handleSubmit}>
          <h4>Please enter the {isSuperAdmin ? "superadmin" : "organization"} details.</h4>
          {/* <p><span>You will recieve 4-digit OTP to your mobile number</span></p> */}
          <div className='mobile_number'>
            <div className='input'>
              <input placeholder='Username' value={formik.values.username} name='username' onChange={formik.handleChange} />
            </div>
            <div className='input'>
              <input placeholder='Password' value={formik.values.password} name='password' type={"password"} onChange={formik.handleChange} />
            </div>

            <div className='terms'>
              <div>
                <button className='login-btn admin-btn' type='submit' disabled={loading}>
                  Submit&nbsp; <img src={arrow} alt="" width={18} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default CorporateLogin;