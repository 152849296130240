import React, { useEffect } from 'react';
import { apis } from '../../../utils/apis';
import { order_status } from '../../../utils/helper';
import useAxios from '../../../utils/useAxios';

const CommonOrderHeader = ({ data }) => {
  const { onSubmit: ordersCountCall, data: orderCountRes } = useAxios({
    url: apis.orders_status_count,
    method: "post"
  })

  useEffect(() => {
    ordersCountCall()
    // eslint-disable-next-line
  }, [data])

  return (
    <div className="isOrdersFiler mt-4 isFlex">
      {/* <OrderBox /> */}
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Received_Active.png")} alt="Received"></img>
        <span className="isLabel">Received</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.received)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Cancelled_Inactive.png")} alt="Cancelled"></img>
        <span className="isLabel">Cancelled</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.cancelled)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Inprocess_Inactive.png")} alt="In Production"></img>
        <span className="isLabel">In Production</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.production)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Dispatch_Inactive.png")} alt="Dispatched"></img>
        <span className="isLabel">In Dispatch</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.dispatch)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/InTransit_Inactive.png")} alt="In Transit"></img>
        <span className="isLabel">In Transit</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.transit)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Delivered_Inactive.png")} alt="Delivered"></img>
        <span className="isLabel">Delivered</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.delivered)?.count || 0}</span>
      </div>
      <div className="isOrdersBox isFlexColumn alignCenter">
        <img height={25} src={require("../../../assets/images/admin-dashboard/Refunded_Inactive.png")} alt="Refunded"></img>
        <span className="isLabel">Refunded</span>
        <span className="isCount">{orderCountRes?.data?.find(ele => ele.label === order_status.refunded)?.count || 0}</span>
      </div>
    </div>
  );
};

export default CommonOrderHeader;