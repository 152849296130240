import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidenav from '../../common/Sidenav.js';

const SuperAdminWrap = () => {
  return <div className='d-flex isMainContainer'>
    <div className='side-nav'>
      <Sidenav />
    </div>
    <div className='content'>
      <Outlet />
      {/* <div className="isPositionBackground">
  <div className="isBackground">
    <img width={255} src={require("../../assets/images/admin-dashboard/Design.png")} alt="Design"></img>
  </div>
</div> */}
    </div>
  </div>
}


export default SuperAdminWrap;