import { useState } from 'react';
import { toast } from 'react-toastify';
import endpoint from './endpoint';

const useAxios = ({ url, method, msg }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const reset = () => {
    setData(null)
    setError(null)
  }

  const onSubmit = (payload) => {
    setLoading(true)
    if (method === 'post' || method === 'put') {
      endpoint[method](url, payload).then(res => {
        setData(res.data)
        if (!msg) {
          if (res.data?.success) {
            toast.success(res.data?.msg)
          } else {
            toast.error(res.data?.msg)
          }
        }
        setLoading(false)
      }).catch(err => {
        setError(err.response.data)
        toast.error(err.response.data?.msg)
        setLoading(false)
      })
    } else {
      endpoint[method](url, { params: payload }).then(res => {
        setData(res.data)
        setLoading(false)
      }).catch(err => {
        setError(err.response.data)
        setLoading(false)
      })
    }
  }

  const reFetch = (data, cb) => {
    setLoading(true)
    if (method === 'post' || method === 'put') {
      endpoint[method](data?.url, data?.payload, {
        responseType: data?.responseType
      }).then(res => {
        setData(res.data)
        if (!msg) {
          if (res.data?.success) {
            toast.success(res.data?.msg)
            cb && cb()
          } else {
            toast.error(res.data?.msg)
          }
        }
        setLoading(false)
      }).catch(err => {
        setError(err.response.data)
        toast.error(err.response.data?.msg)
        setLoading(false)
      })
    } else {
      endpoint[method](data?.url, { params: data?.payload }).then(res => {
        setData(res.data)
        setLoading(false)
      }).catch(err => {
        setError(err.response.data)
        setLoading(false)
      })
    }
  }

  return { loading, data, error, onSubmit, reFetch, reset }
};

export default useAxios;