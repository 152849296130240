import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { get_profile } from "../../../utils/helper";
import useAxios from "../../../utils/useAxios";

const EmployeeDetailsHeader = () => {
  const navigate = useNavigate()
  const { loading, onSubmit, data } = useAxios({
    url: apis.corporate_card_usage,
    method: "post"
  })

  useEffect(() => {
    onSubmit({
      code: get_profile("code")
    })
  }, [])
  return <div className="detailsHeader mt-4">
    <div className="isFlex alignEnd w-100">
      <div className="formGroup">
        <input type="text" className="formInput inputShadow" placeholder={`kobble.in/${get_profile("code")}`} />
        <span className="isFlex alignCenter isIcons">
          <img className="mr-2 Cursor" src={require("../../../assets/images/Icon-copy.svg").default} width="16" alt="Copy" />
          <img className="mr-2 Cursor" onClick={() => {
            navigate("/add-corp-user", {
              state: { code: get_profile("code") }
            })
          }} src={require("../../../assets/images/Icon-share.svg").default} width="16" alt="Share" />
        </span>
      </div>
      <div className="isFlexColumn ml-4">
        <h3 className="title">Works for</h3>
        <div className="roundedBtn mt-2">
          <span>
            <span className="isCount">{loading ? "" : data?.data?.totalCards - data?.data?.utilized}</span>{" "}Signup's
          </span>
        </div>
      </div>
      <div className="isFlexColumn ml-4">
        <h3 className="title">Cards Usage</h3>
        <div className="progressButton isFlex alignCenter mt-2 justifyCenter">
          <span>{loading ? "" : ((data?.data?.utilized / data?.data?.totalCards) * 100).toFixed(2)}% used</span>
        </div>
      </div>
      <div className="isFlexColumn ml-4">
        <h3 className="title">Bulk Upload</h3>
        <div className="isUpload isFlex alignCenter mt-2 isBoxShadow Cursor justifyCenter">
          <img className="mr-1" src={require("../../../assets/images/Icon-upload.svg").default} width="20" alt="Upload" />
          <span>Upload</span>
        </div>
      </div>
      <div className="ml-4">
        <button type="submit" className="btn secondaryButton isBoxShadow">Upgrade</button>
      </div>
    </div>
  </div>
}

export default EmployeeDetailsHeader;