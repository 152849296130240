import React, { useEffect, useState } from "react";
import { apis } from "../../../utils/apis";
import useAxios from "../../../utils/useAxios";
import DummyTable2 from '../features/DummyTable2';
import SuperAdminHeader from '../features/SuperAdminHeader';

const Users = ({ isCorporates }) => {
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 100
  })
  const { loading, reFetch: getUsers, data: users } = useAxios({
    url: (isCorporates ? apis.corp_users : apis.dashboard_users) + `/${filters.page}/${filters.pageSize}`,
    method: "post"
  })

  useEffect(() => {
    getUsers({
      url: (isCorporates ? apis.corp_users : apis.dashboard_users) + `/${filters.page}/${filters.pageSize}`,
      payload: { search: filters?.search }
    })
  }, [isCorporates, filters])

  return <>
    <div className="isOrdersContainer px-25 py-4">
      <SuperAdminHeader label={isCorporates ? "Corporates" : "Users"} isDateFilter={false} setFilters={setFilters} key={"search"} />
      <div className="isUsers mt-4 isFlex">
        <div className="totalUsers isFlex alignCenter">
          <div className="isFlex alignCenter">
            <img width={60} src={require("../../../assets/images/admin-dashboard/All_Users.png")} alt="All users"></img>
            <div className="isFlexColumn totalCount">
              <span className="isLabel">No. of Users</span>
              <span className="isCount">{users?.totalRecords}</span>
            </div>
          </div>
          <div className="isToggleUsers isFlex alignCenter">
            <div className="ActiveItems itemList isFlex alignCenter">
              <img width={50} src={require("../../../assets/images/admin-dashboard/Active_Users.png")} alt="Active"></img>
              <div className="isFlexColumn isUsers">
                <span className="isLabel">Active</span>
                <span className="isCount">{users?.totalRecords}</span>
              </div>
            </div>
            <div className="inActiveItems itemList isFlex alignCenter">
              <img width={50} src={require("../../../assets/images/admin-dashboard/Inactive_Users.png")} alt="Inactive"></img>
              <div className="isFlexColumn isUsers">
                <span className="isLabel">Inactive</span>
                <span className="isCount">{0}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="isCommonDataTable mt-3">
        <DummyTable2 loading={loading} data={users?.data} isCorporates={isCorporates} />
      </div>
    </div>
  </>
}

export default Users;