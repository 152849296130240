import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back from "../../assets/arrow-back.png";
import check from "../../assets/checkmark.svg";
import metal from "../../assets/metal.png";
// import silver from "../../assets/silver_card.svg";
import smile from "../../assets/smile.png";

import { getCards } from '../../utils/helper';
const Cards = () => {
  const navigate = useNavigate()
  const cards = getCards()
  const [selectedCard, setSelectedCard] = useState([]);

  return (
    <div className='cards'>
      <h3>Kobble - Box</h3>
      <p>All Digital Business Card Products</p>
      <div className='kobble-cards'>
        {cards?.map(ele => {
          return <div className='p-relative cursor' onClick={() => {
            let idx = selectedCard?.findIndex(itm => itm.code === ele.code)
            if (idx > -1) {
              let arr = [...selectedCard];
              arr.splice(idx, 1)
              setSelectedCard(arr)
            } else
              setSelectedCard([...selectedCard, ele])
          }}>
            {!ele.isActive &&
              <span className='out-of-stock'>Out Of Stock</span>}
            {selectedCard?.find(itm => itm.code === ele.code) && <img src={check} alt="" width={20} style={{
              position: "absolute",
              right: 10,
              top: 10
            }} />}
            <img src={metal} alt='' className='card-img' />
            <p>{ele.name}</p>
          </div>
        })}
      </div>
      <div className='flex-end mt2'>
        <p className='happy'>
          <img src={smile} alt="" width={20} />
          Happy to go
        </p>
        <div className='d-flex'>
          <button className='back'>  <img src={back} className="rotate" alt="" width={9} /> Back</button>
          <button className='login-btn' onClick={() => {
            navigate("/cart", {
              state: {
                selectedCard
              }
            })
          }}
            disabled={!selectedCard}>Next <img src={back} alt="" width={18} /></button>
        </div>
      </div>
    </div>
  );
};

export default Cards;