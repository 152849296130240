import React from "react";
// import ReactPaginate from "react-paginate";

const Datatable = ({ data, loading, totalCount, onPageChange }) => {
  return <div className="isEmployeerDataTable">
    <table className="w-100">
      <thead>
        <tr>
          <th>User</th>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Designation</th>
          <th>Department</th>
          {/* <th></th> */}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading ? <tr><td colSpan={8} style={{ textAlign: "center" }}>
          Loading
        </td></tr> :
          data?.length > 0 ? data?.map((ele) => {
            return <tr>
              <td>
                <img src={ele.profile_url ? ele.profile_url : require("../../../assets/images/Profile.png")} width={45} alt="Profile" style={{
                  borderRadius: "50%",
                  width: "3.5rem",
                  height: "3.5rem"
                }} />
              </td>
              <td>{ele.firstname} {ele.lastname}</td>
              <td>{ele.country} {ele.mobile}</td>
              <td>{ele.email}</td>
              <td>{ele.designation}</td>
              <td>{ele.department}</td>
              {/* <td>50 Scans</td> */}
              <td>
                <img className="Cursor" src={require("../../../assets/images/Edit.svg").default} width={25} alt="Profile" />
              </td>
            </tr>
          }) : <tr><td colSpan={8} style={{ textAlign: "center" }}>
            No Data
          </td></tr>}
      </tbody>
    </table>
    {/* <ReactPaginate
      onPageChange={onPageChange}
      pageRangeDisplayed={3}
      pageCount={Math.ceil(totalCount / 10)}
    /> */}
  </div>
}

export default Datatable;