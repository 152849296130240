import React from 'react';
import Page1 from './components/website/Page1';
import Page2 from './components/website/Page2';
import Page3 from './components/website/Page3';
import Page4 from './components/website/Page4';
import Page5 from './components/website/Page5';
import Page6 from './components/website/Page6';
import App1 from "./assets/App.png";
import AppQR from "./assets/kobble_download_link.png";
import './_Styles.scss';

const App = () => {
  return (
    <div>
      <Page1 />
      <Page2 />
      <Page4 />
      <Page3 />
      <Page5 />
      <h3 className='mt3' style={{
        textAlign: "center",
        fontSize: "3rem",
      }}>Download Our App and Get Started.</h3>
      <div className='flex-center mt3 gap5 mb4'>
        <div className='flex-center flex-col'>
          <img src={AppQR} alt="" width={200} className="mb2" />
          <a href="https://play.google.com/store/apps/details?id=com.app.kobble" target="_blank" rel="noopener noreferrer"><img className="_12dJy" height="54" alt='' src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_108/play_ip0jfp" /></a>
          {/* <a href="https://itunes.apple.com/in/app/swiggy-food-order-delivery/id989540920" target="_blank" rel="noopener noreferrer"><img class="_37Cft" height="54" src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_108/iOS_ajgrty" /></a> */}
        </div>
        {/* <div>
          <img src={App1} alt="" width={400} />
        </div> */}
      </div>
      <Page6 />
    </div>
  );
};

export default App;