import React, { useEffect } from "react";
import { apis } from "../../../utils/apis";
import { get_profile } from "../../../utils/helper";
import useAxios from "../../../utils/useAxios";

const OverviewBlock = ({ contactsCount }) => {
	const { loading, onSubmit, data } = useAxios({
		url: apis.corporate_card_usage,
		method: "post"
	})

	useEffect(() => {
		onSubmit({
			code: get_profile("code")
		})
	}, [])
	return <>
		<div className="isBlock w-100 isFlex noWrap">
			<div className="isOverviewBlock w-50 noOfEmployees">
				<div className="isFlex spacing alignCenter">
					<h1 className="isCount">{data?.data?.totalCards}</h1>
					<img src={require("../../../assets/images/Employees.svg").default} width={50} alt="Employee" />
				</div>
				<div className="isTitle">
					Number of Employees.
				</div>
			</div>
			<div className="isOverviewBlock w-50 cardsIssued">
				<div className="isFlex spacing alignCenter">
					<h1 className="isCount">{data?.data?.utilized}</h1>
					<img src={require("../../../assets/images/Employees.svg").default} width={50} alt="Number of Cards Issued" />
				</div>
				<div className="isTitle">
					Number of Cards Issued.
				</div>
			</div>
		</div>
		<div className="isBlock w-100 isFlex noWrap">
			<div className="isOverviewBlock w-50 contactsAdded">
				<div className="isFlex spacing alignCenter">
					<h1 className="isCount">{contactsCount}</h1>
					<img src={require("../../../assets/images/Contacts_added.svg").default} width={50} alt="Employee" />
				</div>
				<div className="isTitle">
					Number of Contacts Added.
				</div>
			</div>
			<div className="isOverviewBlock w-50 isNoEmployees">
				<div className="isFlex spacing alignCenter">
					<h1 className="isCount">{0}</h1>
					<img src={require("../../../assets/images/No_of_Employees.svg").default} width={50} alt="Employee" />
				</div>
				<div className="isTitle">
					Number of Scans.
				</div>
			</div>
		</div>
	</>
}

export default OverviewBlock;