import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/logo.svg";

const Header = ({ onlyLogo }) => {
  const navigate = useNavigate()
  return (
    <header>
      <div className='logo' onClick={() => {
        navigate("/")
      }}>
        <img src={logo} alt="" />
      </div>
      {!onlyLogo &&
        <nav>
          <ul>
            <li onClick={() => {
              navigate("/")
            }}>Products</li>
            <li onClick={() => {
              navigate("/about-us")
            }}>About us</li>
            <li>Refer a Friend</li>
            <li>Corporate Solution</li>
            <li><button className='outline-btn cursor' onClick={() => {
              navigate("/login")
            }}>Get Card</button></li>
          </ul>
        </nav>}
    </header>
  );
};

export default Header;