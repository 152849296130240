import React, { useState, useEffect, useRef } from "react";
const OTPComponent = (props) => {
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  // // const [isEdit, setIsEdit] = useState(false);
  // const numKeyCodes = Array.from({ length: 10 }, (_, i) => i + 48);
  // const numPadKeyCodes = Array.from({ length: 10 }, (_, i) => i + 96);

  const otpRefs = useRef([]);

  useEffect(() => {
    otpRefs.current[0].focus();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    console.log([otp1, otp2, otp3, otp4].join(""));
    props.onChange([otp1, otp2, otp3, otp4].join(""));
    // eslint-disable-next-line 
  }, [otp1, otp2, otp3, otp4]);

  return (
    <div className="otpInputBlock">
      <div className="otpInputField">
        <input
          disabled={props.loading}
          name="otp"
          autoComplete="off"
          maxLength={1}
          value={otp1}
          ref={(ref) => (otpRefs.current[0] = ref)}
          onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            if (e.target.value) {
              otpRefs.current[1].select();
            }
            setOtp1(e.target.value)
          }}
        />
      </div>
      <div className="otpInputField">
        <input
          disabled={props.loading}
          name="otp"
          autoComplete="off"
          maxLength={1}
          value={otp2}
          ref={(ref) => (otpRefs.current[1] = ref)}
          onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            if (e.target.value) {
              otpRefs.current[2].select();
            } else {
              otpRefs.current[0].select();
            }
            setOtp2(e.target.value)
          }}
        />
      </div>
      <div className="otpInputField">
        <input
          disabled={props.loading}
          name="otp"
          autoComplete="off"
          maxLength={1}
          value={otp3}
          ref={(ref) => (otpRefs.current[2] = ref)}
          onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            if (e.target.value) {
              otpRefs.current[3].select();
            } else {
              otpRefs.current[1].select();
            }
            setOtp3(e.target.value)
          }}
        />
      </div>
      <div className="otpInputField">
        <input
          disabled={props.loading}
          name="otp"
          autoComplete="off"
          maxLength={1}
          value={otp4}
          ref={(ref) => (otpRefs.current[3] = ref)}
          onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            if (!e.target.value) {
              otpRefs.current[2].select();
            }
            setOtp4(e.target.value)
          }}
        />
      </div>
      {!props.isFourDigit && (
        <>
          <div className="otpInputField">
            <input
              disabled={props.loading}
              name="otp"
              autoComplete="off"
              maxLength={1}
              value={otpRefs.current[4]?.value}
              ref={(ref) => (otpRefs.current[4] = ref)}
            />
          </div>
          <div className="otpInputField">
            <input
              disabled={props.loading}
              name="otp"
              autoComplete="off"
              maxLength={1}
              value={otpRefs.current[5]?.value}
              ref={(ref) => (otpRefs.current[5] = ref)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OTPComponent;