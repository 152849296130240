import React from "react";

const DesignationCard = ({ ele }) => {
    return <div className="isDesignationCard">
        {/* <div className="isFlex alignCenter spacing">
            <button className="btn btn-success">
                <img src={require("../../../assets/images/Scan.svg").default} width="16" alt="Scan" />
                <span>32 Scans</span>
            </button>
            <img className="Cursor" src={require("../../../assets/images/dots.svg").default} width="25" alt="dots" />
        </div> */}
        <div className="isFlexColumn alignCenter mt-2">
            <img src={ele.profile_url ? ele.profile_url : require("../../../assets/images/Profile.png")} width={100} alt="Profile" />
            <span className="isName mt-1">{ele.firstname} {ele.lastname}</span>
            <span className="isDesignation mt-1">{ele.designation}</span>
        </div>
        <div className="departmentDetails mt-2">
            <div className="department isFlex spacing">
                <div className="isFlexColumn">
                    <span>Department</span>
                    <span className="pt-1">{ele.department}</span>
                </div>
                <div className="isFlexColumn">
                    <span>Location</span>
                    <span className="pt-1">{ele.location}</span>
                </div>
            </div>
            <div className="contactInfo mt-3">
                <div className="isFlexColumn">
                    <span className="email">
                        <img src={require("../../../assets/images/email.svg").default} width="16" alt="Grid" />
                        <span className="pl-1" title={ele.email}>{ele.email?.substr(0, 20)}...</span>
                    </span>
                    <span className="phone">
                        <img src={require("../../../assets/images/phone.svg").default} width="16" alt="Grid" />
                        <span className="pl-1">{ele.country} {ele.mobile}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default DesignationCard;