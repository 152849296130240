import React from 'react';
import Header from './Header';
import page1_right_img from "../../assets/page1_right_img.svg"
import Footer from './Footer';
const Page1 = () => {
  return (
    <section className='page-1'>
      <div className='container pt-2'>
        <Header />
      </div>
      <div className='d-flex'>
        <aside className='container'>
          <p className='title'>Welcome to Kobble.</p>
          <p className='main'>Instantly Share Anything.</p>
          <p className='note'>With a tap of your device or scan code, share your info with anyone you meet.</p>
          <button className='more-info'>
            More Info
          </button>
        </aside>
        <aside className='img-sec'>
          <img src={page1_right_img} alt="" />
        </aside>
      </div>
      <Footer />
    </section>
  );
};

export default Page1;