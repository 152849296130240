import moment from "moment";
import React from "react";
import Checkmark from "../../../assets/images/admin-dashboard/Checkmark.png";
import Checkmark_Selected from "../../../assets/images/admin-dashboard/Checkmark_Selected.png";

const CommonDataTable = ({ noCheck, data, loading, setSelectedIds, selectedIds }) => {
  return <div className="isOrdersDataTable">
    <table className="w-100">
      <thead>
        <tr>
          {noCheck ? null : <th></th>}
          <th>ORDER ID</th>
          <th>CUSTOMER</th>
          <th>DATE</th>
          <th>AMOUNT</th>
          <th>PAYMENT</th>
          <th>ORDER STATUS</th>
          <th>INVOICE</th>
        </tr>
      </thead>
      <tbody>
        {loading ? <tr><td colSpan={8} style={{ textAlign: "center" }}>
          Loading
        </td></tr> :
          data?.length > 0 ? data?.map((ele) => {
            return <tr className={selectedIds?.includes(ele._id) ? "isSelected" : ""}>
              {noCheck ? null : <td onClick={() => {
                if (ele._id)
                  if (selectedIds.includes(ele._id)) {
                    let idx = selectedIds.indexOf(ele._id)
                    let selIds = [...selectedIds]
                    selIds.splice(idx, 1)
                    setSelectedIds(selIds)
                  } else {
                    setSelectedIds([...selectedIds, ele._id])
                  }
              }}>
                {ele?.payment_id?.status === "success" && <img className={ele._id ? "Cursor" : ""} width={25} src={selectedIds.includes(ele._id) ? Checkmark_Selected : Checkmark} alt="check"></img>}
              </td>}
              <td>{ele.order_ref_number}</td>
              <td>{ele?.fk_userId?.firstname}</td>
              <td>
                {ele.createdDate && moment(ele.createdDate).format("DD MMM YYYY")}
              </td>
              <td>{ele.total && "Rs." + 0}</td>
              <td>
                {ele?.payment_id?.status && <button className={`btn ${ele?.payment_id?.status === "success" ? "btn-success" : "btn-warning"}`}>{ele?.payment_id?.status}</button>}
              </td>
              <td>
                {ele.order_status && <button className="btn btn-success">{ele.order_status}</button>}
              </td>
              <td>
                <img width={18} src={require("../../../assets/images/admin-dashboard/Invoice.png")} alt="Invoice"></img>
              </td>
            </tr>
          }) : <tr><td colSpan={6} style={{ textAlign: "center" }}>
            No Data
          </td></tr>}
      </tbody>
    </table>
  </div>
}

export default CommonDataTable;