import React from 'react';
import quote from '../../assets/quote.svg'
import user1 from '../../assets/user1.png'
import user2 from '../../assets/user2.png'
import user3 from '../../assets/user3.png'
import logo from "../../assets/logo.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import whatsapp from "../../assets/whatsapp.svg";
import { useNavigate } from 'react-router-dom';

const Page6 = () => {
  const navigate = useNavigate()
  return (
    <div className='page6'>
      {/* <h3>Don’t take our word for it.</h3>
      <p>Join the 250,000+ users who love us already!</p>
      <div className='flex-between mt5'>
        <div className='quote'>
          <img src={quote} alt="" />
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
          <div className='flex-end mr2 mt2'>
            <div className='mr2'>
              <label>Swetha</label>
              <span>Content Writer</span>
            </div>
            <img className='icon' alt='' src={user1} />
          </div>
        </div>
        <div className='quote'>
          <img src={quote} alt="" />
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
          <div className='flex-end mr2 mt2'>
            <div className='mr2'>
              <label>Shiva</label>
              <span>UI / UX Designer</span>
            </div>
            <img className='icon' alt='' src={user2} />
          </div>
        </div>
        <div className='quote'>
          <img src={quote} alt="" />
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
          <div className='flex-end mr2 mt2'>
            <div className='mr2'>
              <label>Nagendra</label>
              <span>Product Manager</span>
            </div>
            <img className='icon' alt='' src={user3} />
          </div>
        </div>
      </div> */}
      <div className='flex-between footer'>
        <div className='logo-wrap'>
          <div className='logo'>
            <img src={logo} alt="" />
          </div>
          <div className='d-flex gap1 socials'>
            <img src={facebook} alt="" />
            <img src={twitter} alt="" />
            <img src={linkedin} alt="" />
            <img src={whatsapp} alt="" />
          </div>
          <p>
            Contact Us:
            <br />
            Plot No: 26, Shobana Colony, Mahatma Gandhi Nagar
            <br /> West Marredpally, Secunderabad
            <br />Telangana - 500026, India
            <br />
            Contact : 8125655446
          </p>
          <p>
            © 2022 all rights reserved
          </p>
        </div>
        <div className='d-flex'>
          <div className='mr2'>
            <label>
              About Us
            </label>
            <ul>
              <li onClick={() => { navigate("/privacy-policy") }}>Privacy policy</li>
              <li onClick={() => { navigate("/terms-and-conditions") }}>Terms and conditions</li>
              <li onClick={() => { navigate("/refund") }}>Refund & Cancellation Policy</li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page6;