export const isAddress = () => {
  const user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
  return user?.address ? user?.address?.length > 0 : false
}

export const getId = () => {
  const user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
  return user?._id
}
export const getMobile = () => {
  const user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
  return user?.mobile
}

export const getCards = () => {
  const cards = sessionStorage.getItem("cards") ? JSON.parse(sessionStorage.getItem("cards")) : {}
  return cards;
}

export const getAccessories = () => {
  const accessories = sessionStorage.getItem("accessories") ? JSON.parse(sessionStorage.getItem("accessories")) : {}
  return accessories;
}
export const calculateDiscount = (amount, percent) => {
  if (percent === 0) {
    return amount;
  }
  return (amount * percent) / 100
}

export const get_profile = (key) => {
  let profile = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : {}
  return profile[key]
}

export const order_status = {
  received: "Received",
  cancelled: "Cancelled",
  dispatch: "Dispatch",
  dispatched: "Dispatched",
  delivered: "Delivered",
  refunded: "Refunded",
  production: "In Production",
  transit: "In Transit",
}