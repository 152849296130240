import moment from "moment/moment"
import React from "react"

const QRTable = ({ loading, data, isCorporates }) => {
  return <div className="isOrdersDataTable">
    <table className="w-100">
      <thead>
        <tr>
          <th>Sl. No</th>
          <th>QR Code</th>
          <th>Created Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? <tr><td colSpan={6} style={{ textAlign: "center" }}>
          Loading
        </td></tr> :
          data?.length > 0 ? data?.map((ele, idx) => {
            return <tr>
              <td>{idx + 1}</td>
              <td>{ele.qr}</td>
              <td>{ele.nfc}</td>
              <td>
                {ele.createdDate && moment(ele.createdDate).format("DD MMM YYYY")}
              </td>
            </tr>
          }) : <tr><td colSpan={6} style={{ textAlign: "center" }}>
            No Data
          </td></tr>}
      </tbody>
    </table>
  </div>
}

export default QRTable;