import React from 'react';
import Header from '../website/Header';

const Privacy = () => {
  return (
    <div className='container page-1'>
      <div className='pt-2'>
        <Header />
      </div>
      <div className='privacy-policy'>
        <div className='pb1 mt3'>
          <h2>Privacy Policy</h2>
          <span className='title'>Kobble is operated by Invent technologies.</span>
        </div>
        <section className='mt2'>
          <p>
            The website https://www.kobble.in (“Website”) is owned and operated by Invent Technologies,
            a company incorporated in India under the Companies Act, 1956 (“Company”). The Company is engaged in the business
            of facilitating a contactless contact exchange platform and creating a business card (virtual 7 physical ) using
            proprietary intellectual platforms on website, Mobile apps & Kobble platforms for individual users, Organisations & corporates
            and other related services (“Services”).
          </p>
          <p>
            This Privacy Policy (“Privacy Policy”) sets out the privacy practices of the Company with respect to the entire content of the Website.
          </p>
          <p>
            This document is published in accordance with the provisions of the Information Technology Act, 2000
            and the rules made thereunder that require publishing the rules and regulations, privacy policy and
            terms of conditions on an online portal of the Company. We request you to go through this Privacy Policy
            and the terms & conditions carefully before you decide to access this Website.
          </p>
          <p>
            For the purposes of this Privacy Policy, the words “us”, “we”, and “our” refer to the Company
            and all references to “you”, “your” or “user”, as applicable mean the person who accesses,
            uses and/or participates in the Website in any manner or capacity.
          </p>
          <p>
            The protection and security of your personal information is our top priority and we have taken
            all necessary and reasonable measures to protect the confidentiality of the user information and
            its transmission through the internet.
          </p>
          <p>
            By using our Services and the Website, mobile apps or by otherwise giving us your information,
            you agree to the terms of this Privacy Policy. You also expressly consent to our use and disclosure of your
            Personal Information (as defined below) in the manner prescribed under this Privacy Policy and further signify your
            agreement to this Privacy Policy and the Terms of Use. If you do not agree to this Privacy Policy, do not subscribe to the Services,
            use the Website and mobile apps or give us any of your information.
          </p>
          <h3>
            COLLECTION OF INFORMATION
          </h3>
          <p>
            We may collect information from you, through your use of the Website or by joining the Kobble community on social
            media websites, or which is provided to one of our marketing partners or through any engagement with Kobble. We may collect and
            process personal information provided by you, including but not limited to:<br /><br />
            I. Information that you provide at the time of registration including any information that identifies
            or can be used to identify, contact or locate the user such as name, address, email address, and phone number.<br />
            ii. Any data that is automatically captured by the Website such as your mobile phone operating system every computer/mobile device
            connected to the internet is given a domain name and a set of numbers that serve as
            that computer's Internet Protocol or “IP” address. When you request a page from any page within the Website,
            our web servers automatically recognize your domain name and IP address. The domain name and IP address reveal
            nothing personal about you other than the IP address from which you have accessed the Website.
          </p>
          <h3>
            USE OF THE INFORMATION COLLECTED
          </h3>
          <p>
            The primary goal of the Company in collecting the information is to provide you with a platform for the purpose of providing the Services.
            The Company may use the Personal Information provided by you in the following ways:<br />
            <ul>
              <li>To help provide you the Services;</li>
              <li>To observe, improve and administer the quality of Service;</li>
              <li>To analyze how the Website, Kobble platforms and mobile app is used, diagnose technical problems;</li>
              <li>Remember the basic information provided by you for effective access;</li>
              <li>To confirm your identity in order to determine your eligibility to use the Website, Kobble platforms & mobile app and avail the Services;</li>
              <li>To notify you about any changes to the Website, Kobble paltforms & mobile apps;</li>
              <li>To enable the Company to comply with its legal and regulatory obligations;</li>
              <li>For the purpose of sending administrative notices and Service-related alerts and similar communication, as detailed under this Privacy Policy, with a view to optimizing the efficiency of the Website, Kobble platforms, products & mobile apps;</li>
              <li>Doing market research, troubleshooting, protection against error, fraud and other criminal activity, project planning; and</li>
              <li>To enforce the Company’s Terms of Use.</li>
            </ul>
            Sale of Assets, Merger, Acquisition, Bankruptcy<br /><br />
            Information collected from you may be transferred to a third party as a result of a sale or acquisition, merger or bankruptcy involving the Company.

          </p>
          <p>

            Consulting<br />
            The Company may partner with another party to provide certain specific services if required.
            When you sign-up for such services, the Company will share names, or other Personal Information that
            is necessary for the third party to provide these Services. The Company’s contractual arrangements with
            such third parties restrict these parties from using personally identifiable information except for the
            explicit purpose of assisting in the provision of these Services.
            <br /><br />
            Targeted Advertising
            <br />
            You expressly acknowledge and agree that we may also share or transfer your Personal Information to third-party
            service providers, for the limited purpose of allowing or permitting such third-party service providers, including Google,
            and other social media websites to display Kobble advertisements and notifications on websites across the Internet, based
            on the information that is collected by Kobble, to optimize and display advertisements based on your past preferences and visits on the Website as part of its Services. The Company does not allow any unauthorized persons or organization to use any information that the Company may collect from you through this Website, Kobble platforms, products & mobile apps.
            <br /><br />
            Regulatory Disclosures<br />
            We cooperate with government and law enforcement officials and private parties to enforce and
            comply with the law. Thus, we may access, use, store, transfer and disclose your information (including Personal Information),
            including disclosure to third parties such as government or law enforcement officials or private parties as we reasonably
            determine is necessary and appropriate:<br /><br />
            (i) to satisfy any applicable law, regulation, governmental requests or legal process;<br />
            (ii) to protect the safety, rights, property or security of the Company, our Services, the Website or any third party;<br />
            (iii) to protect the safety of the public for any reason;<br />
            (iv) to detect, prevent or otherwise address fraud, security or technical issues; and /or<br />
            (v) to prevent or stop any activity we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity. Such disclosures may be carried out without notice to you.
          </p>
          <p>
            Referrals<br />
            When you want a share a service available on the Website, mobile apps to a friend, you and your friend's names
            and e-mail addresses or mobile numbers are requested. Your email and your friends’ e-mail addresses will only
            be used for this purpose unless permission is otherwise granted.

          </p>
          <p>
            Link to Third Part Websites<br />
            This Website may contain links which may lead you to other Websites. Please note that once you leave the Company’s
            Website you will be subjected to the privacy policy of the other Website. The linked websites are not necessarily under
            the control of the Company. Please be aware that the Company is not responsible for the privacy practices of such other sites. The Company encourages you to read the privacy policies of each and every website that collects Personal Information. If you decide to access any of the third-party sites linked to the Website, you do this entirely at your own risk. Any links to any partner of the Company should be the responsibility of the linking party, and the Company will not be responsible for notification of any change in name or location of any information on the Website.
            <br /><br />
            We may further disclose your Personal Information to such third parties to whom we have transferred our rights
            and duties under any agreement entered into with such third parties and may also disclose your Personal Information
            to any of our affiliates, subsidiaries or related entity.
          </p>

          <h3>
            SECURITY OF INFORMATION
          </h3>
          <p>
            <ul>
              <li>The Company has put in place appropriate methods and managerial procedures to safeguard and secure such Personal Information.
                The Company only processes Personal Information in a way that is compatible with and relevant to the purpose for which it was collected or authorized by the user. The Website allows users access to their Personal Information and allows them to correct, amend or delete inaccurate information.</li>
              <li>
                The Company uses commercially reasonable precautions to preserve the integrity and security of your information against loss, theft, unauthorized access, disclosure, reproduction, use or amendment.
              </li>
              <li>
                The information that is collected from you may be transferred to, stored and processed at any destination within and/or outside India. By submitting information on the Website, you agree to this transfer, storing and/or processing. The Company will take such steps as it considers reasonably necessary to ensure that your information is treated securely and in accordance with this Privacy Policy.
              </li>
              <li>
                In using the Website, you accept the inherent security implications of data transmission over the internet. Therefore, the use of the Website will be at your own risk and the Company assumes no liability for any disclosure of information due to errors in transmission, unauthorised third-party access or other acts of third parties, or acts or omissions beyond its reasonable control and you agree not to hold the Company responsible for any breach of security.
              </li>
              <li>
                In the event the Company becomes aware of any breach of the security of your information, it will promptly notify you and take appropriate action to the best of its ability to remedy such a breach.
              </li>
            </ul>
          </p>
          <h3>
            EXCLUSION
          </h3>
          <p>
            <ul>
              <li>This Privacy Policy does not apply to any information other than information collected by the Company through the Website,
                Kobble platforms or products & mobile apps including such information collected in accordance with the clause on “Collection of Information”
                above. This Privacy Policy will not apply to any unsolicited information provided by you through this Website or through any other
                means. This includes, but is not limited to, information posted on any public areas of the Website. All such unsolicited information
                shall be deemed to be non-confidential and the Company will be free to use, disclose such unsolicited information without limitation.</li>
              <li>
                The Company also protects your Personal Information off-line other than as specifically mentioned in this Privacy Policy. Access to your Personal Information is limited to employees, agents or partners and third parties, who the Company reasonably believes will need that information to enable the Company to provide Services to you. However, the Company is not responsible for the confidentiality, security or distribution of your own Personal Information by our partners and third parties outside the scope of our agreement with such partners and third parties.
              </li>
            </ul>
          </p>
          <p>
            DATA RETENTION<br/>
            The Company shall not retain Personal Information longer than is necessary to fulfil the purposes for which it was collected and as permitted by applicable law. If you wish to cancel your account or request that the Company no longer use your information to provide you Services, contact us through email at hello@kobble.in. Even after your account is terminated, the Company may retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to the cancellation of an account, investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason.
            <br/><br/>
            RIGHT TO WITHDRAW CONSENT<br/>
            The consent that you provide for the collection, use and disclosure of your Personal Information will remain valid until such time it is withdrawn by you in writing. If you withdraw your consent, the Company will stop processing the relevant Personal Information except to the extent that the Company has other grounds for processing such Personal Information under applicable laws. The Company will respond to your request within a reasonable timeframe. You may withdraw your consent at any time by contacting the Company or by using the functionalities of the Website.
            <br/><br/>
            RIGHT TO CORRECTION<br/>
            You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. The Company relies on the users to disclose to it all relevant and accurate information and to inform the Company of any changes. If you wish to make a request to correct or update any of the personal data which we hold about you, you may submit your request in writing or via email to hello@kobble.in.
            <br/><br/>
            ACCESS OUTSIDE INDIA<br/>
            The Company makes no representation that the Website is appropriate to be used or accessed outside India. This document is published in accordance with the provisions of the Information Technology Act, 2000 and the Company is compliant with the laws of India. If any users use or access the Website from outside India, they do so at their own risk and are responsible for compliance with the laws of such jurisdiction and the Company shall not have any responsibility for or liability arising from, such compliance with local laws. This Privacy Policy does not constitute, nor may it be used for or in connection with, any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation is not authorized or to any person to whom it is unlawful to promote or solicit.
            <br/><br/>
            NOTIFICATION OF CHANGES<br/>
            From time to time, the Company may update this Privacy Policy to reflect changes to its information practices. Any changes will be effective immediately upon the posting of the revised Privacy Policy. We encourage you to periodically review this page for the latest information on our privacy practices.
            <br/><br/>
            OPT-IN AND OPT OUT PROCEDURES<br/>
            Upon registration, you may receive certain communication from the Company and you may at any time choose to opt-out of receiving further communications from the Company. To be completely removed from the Company’s mailing list, you may contact us at hello@kobble.in. If you are using an e-mail forwarding service or other similar services please make sure to include the correct e-mail address or addresses.

          </p>
          <p>
            GRIEVANCE OFFICER<br />
            In accordance with the Information Technology Act, 2000 and the rules made thereunder, the name and contact details of the Grievance Officer are provided below. Any User grievance relating to the discrepancies or misuse of information so provided to Kobble may be addressed to the grievance officer, whose details are provided below appointed by Kobble for this purpose.
            <br /><b>Name: </b> David (Managing Partner)<br />
            <b>Contact Number: </b> 8125655446<br />
            <b>Email Id: </b> hello@kobble.in<br />
            The grievance officer shall address the grievance within one month of the date of receipt of such grievance from the User.

          </p>
          <p>
            ADDRESS FOR PRIVACY QUESTIONS<br />
            Should you have questions about this Privacy Policy or Company’s information collection, use and disclosure practices, you may contact us at: hello@kobble.in. We will use reasonable efforts to respond promptly to requests, questions or concerns you may have regarding our use of Personal Information about you. Except where required by law, the Company cannot ensure a response to questions or comments regarding topics unrelated to this policy or Company’s privacy practices.
          </p>
          <h3>CONTACT DETAILS</h3>
          <p>
            If you have any other questions or comments about our Cookie Policy, please contact us:<br />
            <b>Name: </b> David (Managing Partner)<br />
            <b>Contact Number: </b> 8125655446<br />
            <b>Email Id: </b> hello@kobble.in<br />
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;