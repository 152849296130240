import React, { useEffect } from 'react';
import './corporate-admin.scss';
import OverviewCard from './features/OverviewCard';
import OverviewBlock from './features/OverviewBlock';
import OverviewGraph from './features/OverviewGraph';
import ContactList from './features/ContactList';
import CorpAdminHeader from '../super-admin/features/CorpAdminHeader';
import { apis } from '../../utils/apis';
import { get_profile } from '../../utils/helper';
import useAxios from '../../utils/useAxios';

const Home = () => {

  const { loading, reFetch, data } = useAxios({
    url: apis.corporate_connections,
    method: "post"
  })

  useEffect(() => {
    reFetch({
      url: apis.corporate_connections,
      method: "post",
      payload: {
        code: get_profile("code")
      }
    })
  }, [])
  return (
    <div className='isDashboard px-25 py-4'>
      <CorpAdminHeader label={"Dashboard"} />
      <div className='isFlex alignStart isDashboardSections spacing'>
        <div className='leftPanel'>
          <h1 className='title'>Overview</h1>
          <OverviewCard />
          <div className='mt-4'>
            <div className='isOverviewBlocks isFlex w-100'>
              <OverviewBlock contactsCount={data?.data?.length} />
            </div>
          </div>
        </div>
        <div className='rightPanel'>
          <h1 className='title'>Usage of No. of Scans</h1>
          <OverviewGraph />
          <div className='mt-4'>
            <h1 className='title'>Contacts Added List</h1>
            <ContactList loading={loading} data={data?.data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;