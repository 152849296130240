import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow from "../../assets/arrow-up-right.svg";
import login_lady from "../../assets/login_lady.png";
import user from "../../assets/user1.png";
import { apis } from '../../utils/apis';
import useAxios from '../../utils/useAxios';
import Country from '../form-components/Country';
import Header from '../website/Header';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  mobile: Yup.string().required("Mobile number is required").min(10, "Enter valid mobile number").max(10, "Enter valid mobile number").matches(/^[0-9]*$/, "Enter valid mobile number")
})

const Login = () => {
  const navigate = useNavigate();
  const { loading, onSubmit, data } = useAxios({
    url: apis.otp,
    method: "post"
  })
  const formik = useFormik({
    initialValues: {
      mobile: "",
      country: "+91",
      terms: false
    },
    onSubmit: (vals) => {
      onSubmit(vals)
    },
    validationSchema: validationSchema
  })

  useEffect(() => {
    if (data?.success) {
      navigate("/verify-otp", {
        state: {
          mobile: formik.values.mobile,
          userId: data?.token
        }
      })
    }
    // eslint-disable-next-line
  }, [data])
  return (
    <div className='login-page'>
      <Header onlyLogo={true} />
      <div className='flex-between mt4'>
        <img className='login_lady' src={login_lady} alt="" />
        <form className='w40 login-form' onSubmit={formik.handleSubmit}>
          <h4>Enter your mobile number</h4>
          <p><span>You will recieve 4-digit OTP to your mobile number</span></p>
          <div className='mobile_number'>
            <Country dark={true} />
            <div className='mobile'>
              <span>+91</span>
              <input placeholder='Mobile Number' name='mobile' onChange={formik.handleChange} />
            </div>
            <div className='terms'>
              <div className='p-relative mt1'>
                <input type={"checkbox"} hidden={true} id="input_check" name='input_check' onChange={(e) => { formik.setFieldValue("terms", e.target.checked) }} value={formik.values.terms} />
                <span className="checkmark" onClick={() => {
                  document.getElementById("input_check").click()
                }}></span>
                <label className='ml2 agree cursor' htmlFor='input_check'>I agree to terms - privacy policy and allow access to my information.</label>
              </div>
              <div>
                <button className='login-btn' type='submit' disabled={loading || !formik.values.terms}>
                  <img src={arrow} alt="" width={18} />
                </button>
              </div>
            </div>
            <div>
              <button className='corporate-btn' onClick={() => {
                navigate("/corporate-register")
              }}>
                <img src={user} alt="" width={20} className="mrHalf" />
                Are you a corporate user ?</button>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default Login;