import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from '../App';
import Address from './app/Address';
import Cards from './app/Cards';
import Cart from './app/Cart';
import Login from './app/Login';
import OTPForm from './app/OTPForm';
import Privacy from './app/Privacy';
import TermsNConditions from './app/TermsNConditions';
import ThankYou from './app/ThankYou';
import CorporateAdminWrap from './corporate-admin/CorporateAdminWrap';
import SuperAdminWrap from './super-admin/SuperAdminWrap';
import CorporateCard from './corporateCard/CorporateCard';
import Home from './corporate-admin/Home';
import SuperAdminDashabord from './super-admin/SuperAdminDashabord';
import OrdersContainer from './super-admin/OrdersContainer';
import EmployeeData from './corporate-admin/EmployeeData';
import EmployeeDesignationList from './corporate-admin/EmployeeDesignationList';
import EditProfile from './corporate-admin/EditProfile';
import ShareProfile from './app/ShareProfile';
import CorporateSignUp from './app/CorporateRegister';
import CorporateLogin from './app/CorporateLogin';
import Users from './super-admin/features/Users';
import ListShow from './corporate-admin/ListShow';
import AboutUs from './app/AboutUs';
import Refund from './app/Refund';
import QRTableComp from './super-admin/features/QRTable';
import Qr_NFC from './app/QR_NFC';

const ProtectedRoute = ({ children, to }) => {
  if (localStorage.getItem("profile")) {
    return (
      children
    );
  }

  return <Navigate to={to || "/"} />
};

const Main = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/corporate-card" element={<CorporateCard />}>
          </Route>
          <Route path="/corporate-admin" element={<ProtectedRoute to={"/corporate-login"}>
            <CorporateAdminWrap />
          </ProtectedRoute>}>
            <Route index={true} element={<Home />} />
            <Route path='' element={<EmployeeDesignationList />}>
              <Route path="cards" element={<EmployeeData />} />
              <Route path="connections" element={<ListShow />} />
            </Route>
            <Route path="profile" element={<EditProfile />} />
          </Route>
          <Route path="/add-corp-user" element={<CorporateCard />} />
          {/* <Route path="/:code" element={<CorporateCard />} /> */}
          <Route path="/super-admin" element={<ProtectedRoute to={"/super-admin-login"}><SuperAdminWrap /></ProtectedRoute>}>
            <Route index={true} element={<SuperAdminDashabord />} />
            <Route index="dashboard" element={<SuperAdminDashabord />} />
            <Route path='orders' element={<OrdersContainer />} />
            <Route path='corporates' element={<Users isCorporates={true} />} />
            <Route path='orders/:status' element={<OrdersContainer />} />
            <Route path='users' element={<Users />} />
            <Route path='qr-nfc' element={<QRTableComp />} />
          </Route>
          <Route path='/' element={<App />} />
          <Route path='/:profileId' element={<ShareProfile />} />
          <Route path='/:profileId/share' element={<ShareProfile isShare={true} />} />
          <Route path='/link/:profileId' element={<ShareProfile />} />
          <Route path='/cards/:profileId/knp' element={<Qr_NFC isNFC={true} />} />
          <Route path='/cards/:profileId/kqrp' element={<Qr_NFC />} />
          <Route path='/cards/:profileId/knm' element={<Qr_NFC isNFC={true} />} />
          <Route path='/cards/:profileId/kqrm' element={<Qr_NFC />} />
          <Route path='/cards/:profileId/kqri' element={<Qr_NFC />} />
          <Route path='/address' element={<Address />} />
          <Route path='/login' element={<Login />} />
          <Route path='/corporate-register' element={<CorporateSignUp />} />
          <Route path='/corporate-login' element={<CorporateLogin />} />
          <Route path='/super-admin-login' element={<CorporateLogin isSuperAdmin={true} />} />
          <Route path='/verify-otp' element={<OTPForm />} />
          <Route path='/cards' element={<Cards />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/thank-you' element={<ThankYou />} />
          <Route path='/terms-and-conditions' element={<TermsNConditions />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/refund' element={<Refund />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </>
  );
};

export default Main;