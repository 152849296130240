import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import './SearchInput.scss';

const SearchInput = ({
  onSearch
}) => {
  const [search, setSearch] = useState("")
  const searchRef = useRef()

  useEffect(() => {
    searchRef.current = setTimeout(() => {
      onSearch && onSearch(search)
    }, 700)

    return () => {
      if (searchRef.current && search) {
        clearTimeout(searchRef.current)
      }
    }
  }, [search])

  console.log({ search });

  return <div className="isSearchInput">
    <input type="text" className="inputSearch" value={search} placeholder="Search …" onChange={(e) => {
      setSearch(e.target.value)
    }} />
    <img width={22} src={require("../assets/images/admin-dashboard/Search.png")} alt="search input"></img>
  </div>
};

export default SearchInput;