import React from 'react';
import Header from '../website/Header';

const Refund = () => {
  return (
    <div className='container page-1 refund'>
      <div className='pt-2'>
        <Header />
      </div>
      <div className='privacy-policy'>
        <div className='pb1 mt3'>
          <h2>Refunds & Cancellation policy</h2>
        </div>
        <section className='mt2'>
          <h3>
            Refunds
          </h3>
          <p>
            In cases, Kobble is not able to create a virtual kobble card post payment due to any technical difficulties or any issuer / acquirer bank issue or payment gateway issue, Kobble will refund the payments back to the source within 7-8 working days. Customer can call/mail our customer support team.
          </p>
          <h3>
            Cancellation policy
          </h3>
          <p>
            Any user on the Kobble website or mobile app or platforms can create an account and generate theKObble cards (Physical/virtual) and access to the cards post completion of payment. Digital or virtual card will be availed and can be accessed by the user instantly & physical cards will be delivered to the user as per the address given by the third-party delivery partner. Users can cancel the cards or purchases made on the kobble website or mobile apps or kobble platforms within 24hrs from the payment or receipt of invoice or receipt of order copy whicher is earlier.
          </p>

        </section>
      </div>
    </div>
  );
};

export default Refund;