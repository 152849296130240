import React from 'react';
import Compatible from "../../assets/compatible.svg";

const Footer = () => {
  return (
    <footer>
      <div className='item'>
        <img src={Compatible} width={18} alt="" />
        <div>
          <span>Instant</span>
          <span>Share anything with a tap or scan.</span>
        </div>
        <div className='line'></div>
      </div>
      <div className='item'>
        <img src={Compatible} width={18} alt="" />
        <div>
          <span>Easy</span>
          <span>Others don't need an app.</span>
        </div>
        <div className='line'></div>
      </div>
      <div className='item'>
        <img src={Compatible} width={18} alt="" />
        <div>
          <span>Compatible</span>
          <span>Works on Apple and Android.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;