import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../common/SearchInput';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const SuperAdminHeader = ({ isDateFilter, label, isSearch, setFilters, key }) => {
  const navigate = useNavigate()
  const [openDate, setOpenDate] = useState(false)
  const [filters, set_Filters] = useState({
    fromDateFilter: "",
    toDateFilter: ""
  })

  useEffect(() => {
    if (filters.fromDateFilter && filters.toDateFilter) {
      setFilters(fil => ({ ...fil, ...filters }))
    }
  }, [filters])
  return (
    <div className='isFlex alignCenter spacing super-admin'>
      <h1 className='title'>{label}</h1>
      <div className='isFlex alignCenter'>
        {isDateFilter &&
          <>
            <ReactDatePicker
              open={openDate}
              monthsShown={2}
              // dateFormat={"YYYY/MM/DD"}
              startDate={filters.fromDateFilter ? new Date(filters.fromDateFilter) : null}
              endDate={filters.toDateFilter ? new Date(filters.toDateFilter) : null}
              selectsRange={true}
              onChange={(dates) => {
                const [start, end] = dates;
                if (start && !end) {
                  set_Filters(d => ({
                    ...d,
                    fromDateFilter: moment(start).format("YYYY-MM-DD"),
                    toDateFilter: ""
                  }))
                }
                if (end) {
                  set_Filters(d => ({
                    ...d,
                    toDateFilter: moment(end).format("YYYY-MM-DD")
                  }))
                  setOpenDate(false)
                }


              }}
              customInput={<button className='mr-2 iconFarward date Cursor' >
                {filters.fromDateFilter && filters.toDateFilter && <span onClick={() => {
                  setFilters({
                    fromDateFilter: "",
                    toDateFilter: ""
                  })
                }}>
                  {moment(filters.fromDateFilter).format("DD MMM, YY")} - {moment(filters.toDateFilter).format("DD MMM, YY")}
                </span>}
                <img width={40} src={require("../../../assets/images/admin-dashboard/Calendar.png")} alt="Calendar" onClick={() => {
                  console.log("clicked");
                  setOpenDate(b => !b)
                }} />
              </button>}
            />
          </>}
        {!isSearch && <SearchInput onSearch={(searchFilter) =>
          setFilters(fil => ({ ...fil, [key]: searchFilter }))
        } />}
        <span className='ml-2 iconFarward Cursor' onClick={() => {
          localStorage.clear()
          navigate("/")
        }}>Logout&nbsp;&nbsp;
          <img width={25} src={require("../../../assets/images/admin-dashboard/Logout.png")} alt="Arrow"></img>
        </span>
      </div>
    </div>
  );
};

export default SuperAdminHeader;