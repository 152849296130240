import React, { useEffect, useState } from 'react';
import { apis } from '../../utils/apis';
import { get_profile } from '../../utils/helper';
import useAxios from '../../utils/useAxios';
import Datatable from './features/Datatable';
import DesignationCard from './features/DesignationCard';

const ListShow = () => {
  const { loading, reFetch, data } = useAxios({
    url: apis.corporate_connections,
    method: "post"
  })

  const [switchTable, setSwitch] = useState(true)

  useEffect(() => {
    reFetch({
      url: apis.corporate_connections,
      method: "post",
      payload: {
        code: get_profile("code")
      }
    })
  }, [])
  return (
    <div>
      <div className="px-25 isFlex alignCenter spacing">
        <h1 className="title">Total Connections - {data?.data?.length}</h1>
        <div className="isTotalEmployees">
          <span className="searchByUser">
            <input type="text" className="formInput inputShadow" placeholder="Search user" />
            <img src={require("../../assets/images/Icon-search.svg").default} width="16" alt="Search" />
          </span>
          <span className="viewType ml-4">
            <span className="isTableview mr-2 pr-2">
              <img className="Cursor" src={require("../../assets/images/Table-view.svg").default} width="22" alt="Table" onClick={() => {
                setSwitch(true)
              }} />
            </span>
            <span className="isGridview">
              <img className="Cursor" src={require("../../assets/images/Grid-view.svg").default} width="22" alt="Grid" onClick={() => {
                setSwitch(false)
              }} />
            </span>
          </span>
        </div>
      </div>
      <div className="px-25 mt-5">
        {switchTable ? <div className="px-25 mt-5">
          <Datatable data={data?.data} loading={loading} />
        </div> :
          <div className="isDesignationCardsList isFlex">
            {data?.data?.length ? data?.data?.map(ele =>
              <DesignationCard ele={ele} />
            ) : <div>
              <p>No Connections Found</p>
            </div>}
          </div>}
      </div>
    </div>
  );
};

export default ListShow;