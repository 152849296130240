import React from "react";

const ContactList = ({ loading, data }) => {

	return <div className="listOfContactsAdded br-20 w-100 isFlex mt-3">
		{loading ? <tr><td colSpan={8} style={{ textAlign: "center" }}>
			Loading
		</td></tr> :
			data?.length > 0 ? data?.slice(0, 10)?.map((ele) => {
				return <div className="addedContact isFlexColumn justifyCenter alignCenter">
					<div className="isProfileLogo">
						<img src={ele.profile_url ? ele.profile_url : require("../../../assets/images/Profile.png")} width={45} alt="Profile" style={{
							borderRadius: "50%",
							width: "2.5rem",
							height: "2.5rem",
							border: "1px solid #0BFFA6"
						}} />
					</div>
					<div className="isName">{ele.firstname} {ele.lastname}</div>
					<span className="isAction">
						<img src={require("../../../assets/images/action.svg").default} height={20} alt="action" />
					</span>
				</div>
			}) : <div>
				<p>No Connections Found</p>
			</div>}
	</div>
}

export default ContactList;