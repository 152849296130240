import React, { useEffect } from 'react';
import orders from "../../assets/images/admin-dashboard/Orders_Recevied.png";
import scans from "../../assets/images/admin-dashboard/Scans.png";
import users from "../../assets/images/admin-dashboard/Users.png";
import { apis } from '../../utils/apis';
import useAxios from '../../utils/useAxios';
import Card from './features/Card';
import DashboardTable from './features/DashboardTable';
import SignUpCount from './features/SignUpCount';
import SuperAdminHeader from './features/SuperAdminHeader';
import TransactionsGraph from './features/TransactionsGraph';
import './super-admin.scss';


const SuperAdminDashabord = () => {
  const { loading: ordersCountLoading, onSubmit: ordersCountCall, data: ordersCount } = useAxios({
    url: apis.dashboard_orders_count,
    method: "post"
  })

  const { loading: usersCountLoading, onSubmit: usersCountCall, data: usersCount } = useAxios({
    url: apis.dashboard_users_count,
    method: "post"
  })
  const { loading: signupCountLoading, onSubmit: signupCountCall, data: signUpCount } = useAxios({
    url: apis.dashboard_signup_count,
    method: "post"
  })

  useEffect(() => {
    ordersCountCall()
    usersCountCall()
    signupCountCall()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='isDashboard px-25 py-4'>
      <SuperAdminHeader label={"Dashboard"} isSearch={true} />
      <div className='isFlex alignStart isSuperAdminDashboard spacing mt-5'>
        <div className='leftPanel'>
          <div className='isFlexColumn alingCenter spacing'>
            <Card label={"users"} img={users} loading={usersCountLoading} data={usersCount?.data} />
            <Card label={"scans"} img={scans} data={usersCount?.data} />
            <Card label={"orders recieved"} loading={ordersCountLoading} img={orders} data={ordersCount?.data} />
          </div>
        </div>
        <div className='rightPanel'>
          <div className='isFlex isDashboardGraphs w-100 noWrap'>
            <div className='w-50 mr-4'>
              <TransactionsGraph label={"No of Transactions"} />
            </div>
            <div className='w-50'>
              <TransactionsGraph label={"Total Revenue"} />
            </div>
          </div>
          <div className='lowerSection mt-4 w-100 isFlex noWrap'>
            <div className='isTable mr-2'>
              <DashboardTable />
            </div>
            <div className='signUpsCount'>
              <SignUpCount loading={signupCountLoading} count={signUpCount?.count} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashabord;