import moment from "moment/moment"
import React from "react"
import Select from 'react-select'
import Checkmark from "../../../assets/images/admin-dashboard/Checkmark.png"
import Checkmark_Selected from "../../../assets/images/admin-dashboard/Checkmark_Selected.png"

const DummyTable2 = ({ loading, data, isCorporates }) => {
  return <div className="isOrdersDataTable">
    <table className="w-100">
      <thead>
        {isCorporates ? <tr>
          <th>ORGANISATION NAME</th>
          <th>ADMIN NAME</th>
          <th>MOBILE</th>
          <th>NO OF EMPLOYEES</th>
          <th>STATUS</th>
          <th>DATE</th>
          <th>PLAN</th>
        </tr> : <tr>
          <th>NAME</th>
          <th>MOBILE</th>
          <th>STATUS</th>
          <th>DATE</th>
          <th>TYPE</th>
        </tr>}
      </thead>
      <tbody>
        {loading ? <tr><td colSpan={6} style={{ textAlign: "center" }}>
          Loading
        </td></tr> :
          data?.length > 0 ? data?.map((ele) => {
            return isCorporates ? <tr>
              <td>{ele.orgName}</td>
              <td>{ele.username}</td>
              <td>+91 {ele.mobile}</td>
              <td>{ele.noOfEmps}</td>
              <td>
                {ele.isActive ? "Active" : "Inactive"}
              </td>
              <td>
                {ele.createdDate && moment(ele.createdDate).format("DD MMM YYYY")}
              </td>
              <td>Basic</td>
            </tr> : <tr>
              <td>{ele.firstname}</td>
              <td>+91 {ele.mobile}</td>
              <td className="isSelect">
                {/* <Select
                /> */}
                {ele.isActive}
              </td>
              <td>
                {ele.createdDate && moment(ele.createdDate).format("DD MMM YYYY")}
              </td>
              <td>{ele.isCorporateUser && ele.isNormalUser ? "Premium" : ele.isCorporateUser ? "Corporate User" : "Individual"}</td>
            </tr>
          }) : <tr><td colSpan={6} style={{ textAlign: "center" }}>
            No Data
          </td></tr>}
      </tbody>
    </table>
  </div>
}

export default DummyTable2;