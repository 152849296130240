import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from "../../assets/arrow-up-right.svg";
import login_lady from "../../assets/otp_lady.png";
import { apis } from '../../utils/apis';
import useAxios from '../../utils/useAxios';
import Header from '../website/Header';
import OTPComponent from './OTPComponent';
const OTPForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOTP] = useState("");
  const { loading, onSubmit, data } = useAxios({
    url: apis.verify_otp,
    method: "post"
  })

  useEffect(() => {
    if (data?.success) {
      sessionStorage.setItem("user", JSON.stringify(data?.data?.user))
      if (!data?.data?.isPaid) {
        sessionStorage.setItem("cards", JSON.stringify(data?.data?.cards))
        sessionStorage.setItem("accessories", JSON.stringify(data?.data?.accessories))
        navigate("/cards")
      } else {
        navigate("/thank-you", {
          state: {
            payment_done: true
          }
        })
      }
    }// eslint-disable-next-line
  }, [data])
  return (
    <div className='login-page'>
      <Header onlyLogo={true} />
      <div className='flex-between mt4'>
        <img className='login_lady otp' src={login_lady} alt="" />
        <form className='w30 login-form otp' onSubmit={(e) => {
          e.preventDefault()
          onSubmit({
            otp,
            _id: location?.state?.userId
          })
        }} >
          <h4>OTP Verification.</h4>
          <p><span>Enter the OTP sent to</span> <strong>
            +91 {location?.state?.mobile}
          </strong></p>
          <div className='mobile_otp'>
            <OTPComponent isFourDigit={true} onChange={(val) => {
              setOTP(val)
            }} />
            <div className='otp-terms'>
              <div>
                <p><span>Didn't recieve otp?</span> <strong className='primary-text'>Resend</strong></p>
              </div>
              <div>
                <button className='login-btn' disabled={otp.length !== 4 || loading}>
                  <img src={arrow} alt="" width={18} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default OTPForm;