import React from 'react';
import Header from '../website/Header';

const AboutUs = () => {
  return (
    <div className='container page-1 about-us'>
      <div className='pt-2'>
        <Header />
      </div>
      <div className='privacy-policy'>
        <div className='pb1 mt3'>
          <h2>About Us</h2>
          <span className='title'>Kobble is operated by Invent technologies.</span>
        </div>
        <section className='mt2'>
          <p>
            Kobble is a new age digital card built for this instant generation to share information of an individual or business in a smart way
          </p>
          <p>
            With a quick scan or a tap you can share your contact. Update your information on the go with the robust mobile application. Generate a QR code by storing the information of your choice - you can store all your social media profiles - Instagram, Facebook, Linkedin, web pages, websites, blogs, tech domains and what not. Customise your mobile app to share the profile/contact of your choice.
          </p>
          <p>
            Get free from the hassles of traditional contact sharing methods by becoming a Kobbler.
            <br />
            Be the next kobbler to use trendy new kobble business card, Kobble mobile app as a new card holder. Make your style with Kobble badges and icons.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;