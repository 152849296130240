import React from "react";
import InputGroup from "../../common/InputGroup";
import SelectInput from "../../common/SelectInput";
import { get_profile } from "../../utils/helper";

const EditProfile = () => {
  return <div className="isEditProfile">
    <div className="isFlex alignStart w-100">
      <div className="profileSection px-2 py-4">
        <div className="isFlexColumn alignCenter">
          <div className="isFlexColumn alignCenter">
            <img src={require("../../assets/images/Profile.png")} width={100} alt="Profile" />
            <span className="isEdit mt-3">Edit Profile photo</span>
          </div>
        </div>
        <form>
          <div className="isProfileForm mt-5">
            <div className="isFormGrouping isFlex noWrap">
              <InputGroup label={"Organization Name"} value={get_profile("orgName")} />
              <InputGroup label={"Email"} value={get_profile("email")} />
            </div>
            <div className="isFormGrouping isFlex noWrap">
              <InputGroup label={"Mobile"} value={get_profile("mobile")} />
              <InputGroup label={"No...Of Employees"} value={get_profile("noOfEmps")} />
            </div>
            {/* <div className="isFormGrouping isFlex noWrap">
              <InputGroup />
              <InputGroup />
            </div> */}
            {/* <div className="isFormGrouping isFlex noWrap">
              <SelectInput />
            </div> */}
            <div className="text-center">
              <button className="btn btn-success w-50">Save & Update</button>
            </div>
          </div>
        </form>
      </div>
      <div className="profilePlan px-2 py-4">
        <div className="isFlexColumn alignCenter">
          <img src={require("../../assets/images/Plan-Card.png")} width="100%" alt="Profile" />
          <div className="isPlanDetails mt-4 isFlexColumn alignCenter">
            <span className="planInfo mb-3 text-center">Now you're using <span className="Cursor">Popular plan</span>. 5 cards (1000 Rs)</span>
            <button type="submit" className="btn btn-warning">Upgrade plan</button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default EditProfile;