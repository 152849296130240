import React, { useEffect, useState } from "react";
import { apis } from "../../../utils/apis";
import useAxios from "../../../utils/useAxios";
import QRTable from '../features/QRRows';
import SuperAdminHeader from '../features/SuperAdminHeader';
import { saveAs } from "file-saver";

function s2ab(s) {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}


const QRTableComp = () => {
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 100
  })
  const { loading, reFetch: getQRCodes, data: qrs } = useAxios({
    url: apis.qr_codes_fetch + `/${filters.page}/${filters.pageSize}`,
    method: "post"
  })

  const { loading: genLoading, reFetch: saveQR } = useAxios({
    url: apis.save_qr_nfc,
    method: "post"
  })

  const { loading: downLoading, reFetch, data } = useAxios({
    url: apis.download_qr_nfc,
    method: "post"
  })

  useEffect(() => {
    getQRCodes({
      url: apis.qr_codes_fetch + `/${filters.page}/${filters.pageSize}`,
      payload: { search: filters?.search }
    })
  }, [filters])

  useEffect(() => {
    if (data) {
      const excelBlob = new Blob([s2ab(data)], {
        type: "application/octet-stream",
      });
      saveAs(excelBlob, "qr_nfc.xlsx");
    }
  }, [data])

  return <>
    <div className="isOrdersContainer px-25 py-4">
      <SuperAdminHeader label={"QR Codes"} isDateFilter={false} setFilters={setFilters} key={"search"} />
      <div className="isUsers mt-4 isFlex">
        <div className="totalUsers isFlex alignCenter">
          <div className="isFlex alignCenter">
            <img width={60} src={require("../../../assets/images/admin-dashboard/All_Users.png")} alt="All users"></img>
            <div className="isFlexColumn totalCount">
              <span className="isLabel">No. of QR/NFC</span>
              <span className="isCount">{qrs?.count}</span>
            </div>
          </div>
          <div className="isToggleUsers isFlex alignCenter">
            <button className="btn btn-success" disabled={genLoading} onClick={() => {
              saveQR({
                url: apis.save_qr_nfc,
              }, () => {
                getQRCodes({
                  url: apis.qr_codes_fetch + `/${filters.page}/${filters.pageSize}`,
                  payload: { search: filters?.search }
                })
              })
            }}>Generate</button>
            <button className="btn btn-success ml1" disabled={downLoading} onClick={() => {
              reFetch({
                url: apis.download_qr_nfc,
                payload: {
                  format: "xlsx"
                },
                responseType: "binary"
              })
            }}>Download</button>
          </div>
        </div>
      </div>
      <div className="isCommonDataTable mt-3">
        <QRTable loading={loading} data={qrs?.data} />
      </div>
    </div>
  </>
}

export default QRTableComp;