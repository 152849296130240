import React from 'react';
import card from "../../assets/card.svg"

const Page2 = () => {
  return (
    <section className='page-2 pb5'>
      <h2>Buy Your Kobble Cards !</h2>
      <div className='flex-between w80'>
        <div className='metal'>
          <div className='img-wrap'>
            <img src={card} alt="" />
          </div>
          <h6>Kobble metal card</h6>
          <button className='outline-btn'>Buy Now</button>
        </div>
        <div className='line'></div>
        <div className='basic'>
          <div className='img-wrap'>
            <img src={card} alt="" />
          </div>
          <h6>Kobble basic card</h6>
          <button className='outline-btn'>Buy Now</button>
        </div>
      </div>
    </section>
  );
};

export default Page2;