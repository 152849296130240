import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidenav from '../../common/Sidenav.js';

const CorporateAdminWrap = () => {
  return (
    <div className='d-flex isMainContainer'>
      <div className='side-nav'>
        <Sidenav fromCorporate={true} />
      </div>
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
};

export default CorporateAdminWrap;