import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cards from "../assets/images/sidenav/Cards.svg";
import Cards_active from "../assets/images/sidenav/Cards_active.svg";
import Connections from "../assets/images/sidenav/Connections.svg";
import Connections_active from "../assets/images/sidenav/Connections_active.svg";
import dashboard_active from "../assets/images/sidenav/dashboard_active.svg";
import dashboard from "../assets/images/sidenav/dashboard.svg";
import Profile from "../assets/images/sidenav/Profile.svg";
import Production_active from "../assets/images/admin-dashboard/Production_Active.png";
import Production_inactive from "../assets/images/admin-dashboard/Production_Inactive.png";
import Profile_active from "../assets/images/sidenav/Profile_active.svg";
import './Sidenav.scss';

const Sidenav = ({ fromCorporate }) => {
  const navigate = useNavigate()
  const location = useLocation()
  return <div className="isSidenavContainer">
    <div className="isLogo isFlexColumn alignCenter">
      <img className="mb-2" width={30} src={require("../assets/images/KOBBLE.svg").default} alt="KOBBLE Corporate"></img>
      {/* <span className="brandTitle text-center">KOBBLE Corporate</span> */}
    </div>
    <div className="sideNavMenu">
      <ul>
        <li className={`navItems ${location.pathname === "/corporate-admin" || location.pathname === "/super-admin" ? "active" : ""}`} onClick={() => {
          if (fromCorporate) {
            navigate("/corporate-admin")
          } else {
            navigate("/super-admin")
          }
        }}>
          <img src={location.pathname === "/corporate-admin" || location.pathname === "/super-admin" ? dashboard_active : dashboard} width={20} alt="dashboard" />
          <span className="sideNavTitle">Dashboard</span>
        </li>

        {fromCorporate ? <>
          <li className={`navItems ${location.pathname === "/corporate-admin/cards" ? "active" : ""}`} onClick={() => {
            if (fromCorporate) {
              navigate("/corporate-admin/cards")
            }
          }}>
            <img src={location.pathname === "/corporate-admin/cards" ? Cards_active : Cards} width={20} alt="Cards" />
            <span className="sideNavTitle">Cards</span>
          </li>
          <li className={`navItems ${location.pathname === "/corporate-admin/connections" ? "active" : ""}`} onClick={() => {
            if (fromCorporate) {
              navigate("/corporate-admin/connections")
            }
          }}>
            <img src={location.pathname === "/corporate-admin/connections" ? Connections_active : Connections} width={20} alt="Connections" />
            <span className="sideNavTitle">Connections</span>
          </li>
          <li className={`navItems ${location.pathname === "/corporate-admin/profile" ? "active" : ""}`} onClick={() => {
            if (fromCorporate) {
              navigate("/corporate-admin/profile")
            }
          }}>
            <img src={location.pathname === "/corporate-admin/profile" ? Profile_active : Profile} width={20} alt="Profile" />
            <span className="sideNavTitle">Profile</span>
          </li>
        </> : <>
          <li className={`navItems ${location.pathname === "/super-admin/orders" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/orders")
          }}>
            <img src={location.pathname === "/super-admin/orders" ? Cards_active : Cards} width={20} alt="Cards" />
            <span className="sideNavTitle">Orders</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/orders/production" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/orders/production")
          }}>
            <img src={location.pathname === "/super-admin/orders/production" ? Production_active : Production_inactive} width={20} alt="Connections" />
            <span className="sideNavTitle">Production</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/orders/dispatch" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/orders/dispatch")
          }}>
            <img src={location.pathname === "/super-admin/orders/dispatch" ? Connections_active : Connections} width={20} alt="Connections" />
            <span className="sideNavTitle">Dispatch</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/orders/transit" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/orders/transit")
          }}>
            <img src={location.pathname === "/super-admin/orders/transit" ? Connections_active : Connections} width={20} alt="Connections" />
            <span className="sideNavTitle">Transit</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/orders/delivered" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/orders/delivered")
          }}>
            <img src={location.pathname === "/super-admin/orders/delivered" ? Connections_active : Connections} width={20} alt="Connections" />
            <span className="sideNavTitle">Delivered</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/users" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/users")
          }}>
            <img src={location.pathname === "/super-admin/users" ? Profile_active : Profile} width={20} alt="Users" />
            <span className="sideNavTitle">Users</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/corporates" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/corporates")
          }}>
            <img src={location.pathname === "/super-admin/corporates" ? Profile_active : Profile} width={20} alt="Corporates" />
            <span className="sideNavTitle">Corporates</span>
          </li>
          <li className={`navItems ${location.pathname === "/super-admin/qr-nfc" ? "active" : ""}`} onClick={() => {
            navigate("/super-admin/qr-nfc")
          }}> <span className="sideNavTitle">
              QR/NFC
            </span>
          </li>
        </>}

      </ul>
    </div>
  </div>
}

export default Sidenav;