import React from 'react';
import Header from '../website/Header';

const TermsNConditions = () => {
  return (
    <div className='container page-1'>
      <div className='pt-2'>
        <Header />
      </div>
      <div className='privacy-policy termsNConditions'>
        <div className='pb1 mt3'>
          <h2>Terms & Conditions</h2>
          <span className='title'>Kobble is operated by Invent technologies. Last Updated on 24 July, 2022</span>
        </div>
        <section className='mt2'>
          <p>
            Unless otherwise specified, the capitalized terms shall have the meanings set out below:
            <br />
            <br />
            Account means and includes the account created on the Site, by the User, in accordance
            with the terms of the Agreement, registered with and approved by.
            <br />
            Agreement means and includes the Terms and Conditions, Privacy Policy and any other such terms and conditions that may be mutually agreed upon between and the User in relation to the Services.
            <br />
            <br />
            Applicable Law means and includes any statute, law, regulation, sub-ordinate legislation, ordinance, rule, judgment, rule of law, order (interim or final), writ, decree, clearance, Authorizations, approval, directive, circular guideline, policy, requirement, code of practice or guidance note, or other governmental, regulatory, statutory, administrative restriction or any similar form of decision, or determination by, or any interpretation or administration of any of the foregoing by, any statutory or regulatory authority or government agency or any other authority, in each case having jurisdiction over the subject matter of this Agreement.
          </p>
          <p>
            Computer Virus means and includes any computer instruction, information, data or programme that destroys, damages, degrades or adversely affects the performance of a computer resource or attaches itself to another computer resource and operates when a programme, data or instruction is executed or some other event takes place in that computer resource.</p>
          <p>
            Confidential Information means and includes all information that is not in the public domain,
            in spoken, printed, electronic or any other form or medium, relating directly or indirectly to,
            the assets, business processes, practices, methods, policies, subscription plans, publications,
            documents, research, operations, services, strategies, techniques, agreements, contracts, terms of agreements,
            transactions, potential transactions, negotiations, pending negotiations, know-how, trade secrets, computer
            programs, computer software, applications, operating systems, software design, web design, work-in-process,
            databases, manuals, records and reports, articles, systems, material, sources of material, supplier identity
            and information, vendor identity and information, User identity and information, financial information,
            results, accounting information, accounting records, legal information, marketing information, advertising
            information, pricing information, credit information, developments, internal controls, security procedures,
            graphics, drawings, sketches, sales information, costs, formulae, product plans, designs, ideas, inventions,
            original works of authorship, discoveries and specifications, of  and/or affiliates or their respective businesses,
            or any existing or prospective customer, supplier, investor or
            other associated third party, or of any other person or entity that has entrusted information to  in confidence.
          </p>
          <p>
            Content means and includes any information all data and information on website.
            <br />
            <br />
            Government Authority means and includes any government, any state or other political subdivision thereof, any entity exercising executive, legislative, judicial, regulatory or administrative functions of or pertaining to government, or any other government authority, agency, department, board, commission or instrumentality or any political subdivision thereof, and any court, tribunal or arbitrator(s) of competent jurisdiction, and, any Government or non-Government self-regulatory organization, agency or authority; having jurisdiction over the Agreement and the Services contemplated under the Agreement.
            <br />
            <br />
            Information Technology Act means the Information Technology Act, 2000.
            <br />
            <br />
            Intellectual Property means and includes patents, inventions, know how, trade secrets, trademarks,
            service marks, designs, tools, devices, models, methods, procedures, processes, systems, principles,
            algorithms, works of authorship, flowcharts, drawings, and other confidential and proprietary information,
            data, documents, instruction manuals, records, memoranda, notes, user guides, ideas, concepts, information,
            materials, discoveries, developments, and other copyrightable works, and techniques in either printed or machine-readable form, whether or not copyrightable or patentable,
            each of which is not in the public domain or would by its very nature fall within public domain.
          </p>
          <p>
            Intellectual Property Rights mean and include
            <br />
            (a) all right, title, and interest under including but not limited to patent,
            trademark, copyright under the Patents Act, 1970, Trademarks Act, 1999 and Copyright Act, 1957
            respectively; any statute or under common law including patent rights; copyrights including moral rights;
            and any similar rights in respect of Intellectual Property, anywhere in the world, whether negotiable or not;
            <br />
            (b) any licenses, permissions and grants in connection therewith;
            <br />
            (c) applications for any of the foregoing and the right to apply for them in any part of the world;
            <br />
            (d) right to obtain and hold appropriate registrations in Intellectual Property;
            <br />
            (e) all extensions and renewals thereof;
            <br />
            (f) causes of action in the past, present or future, related thereto including the rights to damages and profits,
            due or accrued, arising out of past, present or future infringements or violations thereof and the right
            to sue for and recover the same;
            <br />
            (g) any Confidential Information.
          </p>
          <p>
            Kobble means and includes INVENT Technologies, a company incorporated under the Companies Act, 2013 having its registered office at Hyderabad, India including its officers, directors, employees and representatives along with its website.
          </p>
          <p>
            Privacy Policy means and includes the privacy policy of Kobble more particularly described in Section II.
          </p>
          <p>
            Prohibited Conduct means and includes the User’s use of the Service in contravention of the Agreement and Applicable Law; violation of or the abetment of violation of third party rights; infringement or misappropriation of Kobble’s or any of its persons Intellectual Property Rights; attempt to gain or assist another person/ User to gain unauthorized access to the Site and/or Services or its related systems or networks; create internet links to the Site or "frame" or "mirror" any content on any other server or wireless or Internet-based device; act of sending spam, duplicated or unsolicited messages; usage or storage of obscene, threatening, libellous, or otherwise unlawful or tortious material, including material harmful to children or in violation of third party privacy rights leading to harassment, annoyance, anxiety or inconvenience to any person; modify or make derivative works based upon the Service and/or impersonation in relation to any person or entity, claiming a
            false affiliation, accessing any other Account without permission, or falsely representing User Information.
          </p>
          <p>
            Registration Data means and includes the mobile number, e-mail address, username and all such other particulars and/or information as may be required by Kobble and supplied by the User on initial application and subscription.
          </p>
          <p>
            Personal Information means and includes details of Aadhaar Card, PAN Card, biometric information, financial information such as bank account, debit card, credit card details and such other sensitive information, of the User, and/or concerned person which personally identifies such User and/or person.
          </p>
          <p>
            Services means and includes the services provided by Kobble within the territory of India to the User of the Site and shall include the provision of
            <br />(a) Creating a contactless contact exchange platform,
            <br />(b) services to the Users who wish to have a Virtual & physical business card with a QR code,
            <br />(c) services to the Users/Corporates (proprietary, Pvt Ltd, Partnership, LLP of any entity type) who wish to create and make use of Kobble business cards(Virtual & physical) to their employees on website & Kobble corporate,
            <br />(d) services to the Users pertaining to store all their digital accounts in one place
            <br />(e) services to the Users who wish to receive advertisements and promotional messages on the Site and through e-mails and text messages,
            <br />(f) services to the Users allowing them to pay for the services on the Kobble website using third-party payment gateways,
            <br />(g) connecting the Users to various third Party Service Providers for the provision of other related services.
          </p>
          <p>
            Site/ Website means and includes the website owned, operated and managed by Invent Technologies at the URL www.kobble.in
          </p>
          <p>
            Terms and Conditions means and includes the terms and conditions contained in the Agreement.
          </p>
          <p>
            Third Party Service Provider means and includes any service provider with whom Kobble or INVENT technologies has an agreement to provide additional services to the user than available for a user on the kobble site.
          </p>
          <p>
            User means any person who accesses the Site or avails Services on the Site for the purposes of hosting, publishing, sharing, transacting, displaying or uploading information or views and whether as a individual user or as an employer includes other persons jointly participating in using the Site.
          </p>
          <p>
            User Information means and includes Registration Data, Personal Information and any other data, details or information in relation to the User, provided by the User to Kobble in relation to the Services, whether through the Site, e-mail or any other form of communication, agreeable to the User and Kobble.
          </p>
          <h3>
            INTERPRETATION
          </h3>
          <p>
            Unless the context otherwise requires or a contrary indication appears:
            <br />(a) Reference to any legislation or law shall include references to any such legislation or law as it may from time to time be amended, supplemented or re-enacted, and any reference to a statutory provision shall include any subordinate legislation made from time to time under that provision.
            <br />(b) Any reference in this Agreement to a person includes any individual, corporation, partnership, unincorporated organization or governmental agency and shall include their respective successors and permitted assigns and in case of an individual shall include his legal representatives, administrators, executors and heirs and in case of a trust shall include the trustee or the trustees for the time being.
            <br />(c) Headings to Sections and Clauses are for ease of reference only and shall be ignored in the construction of the relevant Sections and Clauses.
            <br />(d) The singular includes the plural and vice versa and words importing a particular gender include all genders.
            <br />(e) The words ‘other’ or ‘otherwise’ and ‘whatsoever’ will not be construed ejusdem generis or be construed as any limitation upon the generality of any preceding words or matters specifically referred to.
          </p>
          <h3>
            ACCEPTANCE OF THE TERMS AND CONDITIONS
          </h3>
          <p>
            The primary goal of the Company in collecting the information is to provide you with a platform for the purpose of providing the Services.
            The Company may use the Personal Information provided by you in the following ways:<br />
            <ul>
              <li>i. Kobble agrees to provide to the User and the User agrees to avail from Kobble Services in accordance with and on the basis of this Agreement and the User’s acceptance of the Terms and Conditions and Privacy Policy constitute the Agreement between the User and Kobble and such Agreement shall deem to replace all previous arrangements between the User and Kobble in relation to the Services and access of the Site.</li>
              <li>ii. The User undertakes to be bound by the Agreement each time the User accesses the Site, and completes the registration process.</li>
              <li>iii. The User represents and warrants to Kobble that the User is 18 (eighteen) years of age or above, and is capable of entering into, performing and adhering to the Agreement. Individuals under the age of 18 (eighteen) may access the Site or avail a Service only with the involvement, guidance, supervision and/or prior consent of their parents and/or legal guardians, through the Account of or under the guidance of such parent/ legal guardian. Kobble shall not be responsible for verifying if the User is at least 18 (eighteen) years of age.</li>
            </ul>
          </p>
          <h3>
            ACCESS TO THE SITE
          </h3>
          <p>
            <ul>
              <li>
                <br />i. First-time Users can access the Site for preliminary browsing without creating an Account.
                <br />ii. The User undertakes and agrees to provide User Information, upload Content and create an account in order to retrieve specific information and avail the Services.
                <br />iii. Kobble shall verify the Account by requesting for a one-time password from the User. The User undertakes and agrees that a mobile number can only be used once to create an Account. The User is prohibited from creating multiple Accounts and Kobble reserves the right to conduct appropriate verification to detect such multiplicity of Accounts and take appropriate actions.
                <br />iv. The User undertakes to cooperate with any of Kobble’s personnel in connecting with the User’s access to the Site, as may be required by Kobble.
              </li>
            </ul>
          </p>
          <h3>
            SUBSCRIPTION PLANS
          </h3>
          <p>
            <ul>
              <li>
                <br />i. Kobble offers a combination of Services which the User may subscribe to, subject to the need of the User, upon payment of applicable fees.
                <br />ii. The subscription fee paid by the User is non-refundable except when the User & kobble agress on mutual understanding as per terms & conditions.
                <br />iii. Kobble reserves the right to revise the fee of any subscription plan without notice to the User at any time prior to, at the time of, during the tenor or post subscription of the plan by the User. Kobble shall intimate the User of such revised fees and the User undertakes to pay to Kobble the difference in the amount after such revision.
              </li>
            </ul>
          </p>
          <h3>
            USER INFORMATION
          </h3>
          <p>
            <ul>
              <li>
                <br />i. Kobble shall not be responsible for the accuracy, quality, integrity, legality, reliability, appropriateness and Intellectual Property Rights of and/or in relation to the User Information.
                <br />ii. Kobble shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any User Information, in whole or in part.
                <br />iii. Kobble reserves the right to withhold access, remove or discard any User Information from the Site, in whole or in part at on its sole discretion.
                <br />iv. Kobble shall have the right but not an obligation to maintain or forward any User Information and to verify the authenticity of any User Information made available to it through the Site or otherwise.
              </li>
            </ul>
          </p>
          <h3>
            RIGHTS OF KOBBLE
          </h3>
          <p>
            <ul>
              <li>
                <br />i. Kobble, at all times, reserves the right, to reject or disable an Account in the event of the User’s violation of any Applicable Law or anything done by the User in contravention of this Agreement and including but not limited for any other reason in relation to the safe and secure operation of the Site and its users.
                <br />ii. Subject to Applicable Law, Kobble has an internal infrastructure in order to conduct a background check and verification of the User Information and the Account,
                Kobble has the right to reject or disable an Account without cause if violating the terms & conditions or any misuse of Kobble or creating fake or inappropriate accounts.
                <br />iii. Kobble has the right to modify, suspend or terminate all or any part of the Services including its features, structure, fees, and layout, and/or suspend or deactivate, whether temporarily or permanently, the User Account and/or User’s access to the Site, at any time, without any prior notice to the User.
                <br />iv. Kobble shall endeavour to provide the User an advance notice, without any obligation to do so, of any suspension or deactivation of the Site for the purposes of repair, any inspection or testing by a Government Authority, or for maintenance, up-gradation, testing or any other reason as Kobble may deem fit. Kobble shall use its best efforts to rectify any disruption of or rectify an error on the Site and restore regular operations of the Site.
                <br />v. Kobble has the right to issue notifications, confirmations and other communications to the User, on the Site, through e-mail, text message or any other form as may be agreed to by the User and/or send promotional or other relevant information in relation to the Services to the User. Kobble may also send users occasional e-mail bulletins unless the Users have opted not to receive these e-mails. These e-mails may also contain neighborhood information, tips, and suggestions or any other offer provided by a third party. Kobble does not take any responsibility of the validity of any of these offers. The Users shall have the right to unsubscribe from receiving any such notifications or promotional information at any time by sending a mail to hello@kobble.in.

              </li>
            </ul>
          </p>
          <h3>
            EUROPEAN UNION DATA PROTECTION RIGHTS
          </h3>
          <p>
            <ul>
              <li>
                <br />i. Subject to Applicable Law and in accordance with the European Union General Data Protection Regulations (EU GDPR), Kobble acknowledges and undertakes to comply on a best effort basis with the rights available to its Users of European nationality/ EU residents set out below:
                <br />(a) the right to know the purposes for which the User Information shall be collected and used;
                <br />(b) the right to access information that personally identifies the User;
                <br />(c) the right to rectify incorrect information;
                <br />(d) the right to delete or remove information after discontinuation of the Services;
                <br />(e) the right to data portability;
                <br />(f) the right to restrict data or information from being processed; and
                <br />(g) the right to opt-out of providing or sharing information with Kobble.
                <br />ii. The Users of Kobble to whom the EU GDPR applies, understand and acknowledge that these rights shall at all times be subject to Applicable Law and are not an exhaustive list of the rights available pursuant to the EU GDPR. The relevant Users shall exercise any of these rights by raising a personal and direct request with Kobble on hello@kobble.in.
              </li>
            </ul>
          </p>

          <h3>UNAUTHORIZED ACCESS</h3>
          <p>
            <ul>
              <li>
                <br /> i. The User shall be liable for all acts conducted through the User’s Account and shall be responsible for the safekeeping of the details and password of the Account.
                <br /> ii. Subject to Applicable Law, the User is responsible for notifying Kobble immediately upon becoming aware of any unauthorized access into or misuse of the Account causing a breach of security as per the Terms and Conditions and Privacy Policy of Kobble.
                <br /> iii. Kobble shall extend support by ensuring immediate termination or suspension of such Account and shall take such other appropriate safety measures as it may deem necessary. Further, Kobble shall not be held liable for any unauthorized access into the Account and/or any loss or damage caused to the User by such unauthorized access or loss or damage caused as a consequence of the delay or failure of the User in informing Kobble about such unauthorized access.
              </li>
            </ul>
          </p>
          <h3>USE OF INFORMATION</h3>
          <p>
            <br />i. Kobble undertakes that the procurement, storage, usage and dissemination of all information including User Information and/or Content, as the case may be, pursuant to this Agreement, shall at all times, including upon expiration of the Services or termination of the Agreement with the User, be in accordance with the Information Technology Act 2000, the rules made thereunder and other relevant Applicable Laws.
            <br /><br />ii. The User hereby irrevocably and unequivocally authorizes Kobble to utilize User Information for the purposes including those set out below:
            <ul>
              <li>
                <br />(a) provision of Services in accordance with the Agreement;
                <br />(b) disclose User Information to its directors, officers, employees, advisors, auditors, counsel, or its authorized representatives on a need-to-know basis for the provision of the Services;
                <br />(c) contacting a third Party Service Provider and/ or facilitating/ enabling the services of a Third Party Service Provider for the User pursuant to the arrangement between Kobble and such Third Party Service Provider;
                <br />(d) conducting internal studies, consumer research, surveys and preparing reports in connection with the Services;
                <br />(e) entering the registration data for an Account or receiving alerts, contacting for delivery of physical cards or any other services provided on the Kobble website.; and
                <br />(f) sending alerts, contact details, promotional messages and promotional calls whether by Kobble itself or through its partners/ vendors and sub-partners/ sub-vendors.
                <br />(g) disclosing to third parties (including law enforcement agencies) personally identifiable information where Kobble has reasonable cause to believe that the Users are guilty of any Prohibited Conduct.
              </li>
            </ul>
            <br />iii. Third party services
            <ul><li>
              <br />(a) Subject to the need and request of the User, Kobble shall engage, directly or indirectly, the services of Third-Party Service Providers from time to time in order to provide the Services to the Users, in accordance with the terms and conditions separately agreed to between Kobble and such Third-Party Service Provider.
              <br />(b) Kobble may recommend services of a Third-Party Service Provider to the User to provide a one stop shop experience in relation to ancillary services, including but not limited to physical gadgets or accessories of Kobble.
              <br />(c) Kobble shall have the unequivocal consent of the User to share User Information, in whole or in part, with the Third-Party Service Provider, without intimation to the User.
              <br />(d) The Site may serve as a platform for relevant and interested Third Party Service Providers for the purposes of advertising or promoting their services in relation to the Services provided by Kobble.
              <br />(e) Nothing contained herein shall constitute or be deemed to constitute an agency or partnership or association of persons for and on behalf of Kobble or any Third-Party Service Provider. The arrangement specified in this clause is strictly executed on a principal to principal basis and each concerned person shall be bound for their distinct responsibilities, rights, liabilities and obligations in accordance with the relevant bilateral agreement between such persons.

            </li></ul>
            <br />iv. Tailored Advertising
            <ul><li>
              <br />(a) The User acknowledges and agrees that Third Party Service Providers may use cookies or similar technologies to collect information about the User’s pattern of availing the Services, in order to inform, optimize, and provide advertisements based on the User’s visits on the Site and general browsing pattern and report how Third-Party Service Providers advertisement impressions, other uses of advertisement services, and interactions with these impressions and services are in relation to the User’s visits on such third party’s website.
              <br />(b) Kobble also permits Third Party Service Providers to serve tailored advertisements to the User on the Site, and further permits them to access their own cookies or similar technologies on the User’s device to access the Site or avail the Services.
              <br />(c) The User undertakes and agrees that when accessing the Services from a mobile application the User may also receive tailored in-application advertisements. Each operating system provides specific instructions on the prevention of tailored in-application advertisements.
              <br />(d) It is to be noted that Kobble does not guarantee the accuracy, integrity or quality of any content provided by such Third-Party Service Provider. Further, the Users interactions with such Third-Party Service Providers found on or through the Services provided by Kobble on the Site, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the Users and the Third Party Service Providers. In no event shall Kobble or Invent technologies be liable for any damages arising out of any interaction between the User and such Third Party Service Provider. The information provided on the Site is provided to the Users on an "AS IS, WHERE IS" basis.

            </li></ul>
          </p>
          <h3>KOBBLE PLATFORM</h3>
          <p>
            The Users agree and acknowledge that Kobble is only an online platform that allows the Users to
            create their business card (digital & physical) and purchase any of the related gadgets and Kobble platforms provided on the site or the Kobble mobile app that can be downloaded from the Playstore for android & Appstore for IOS mobile platforms respectively. The purchased goods & services will be delivered to the user by third party delivery partners.
          </p>
          <h3>KOBBLE TERMS & CONDITIONS</h3>
          <p>
            i. Role: Any registered User of the Site may choose to purchase the Kobble cards by making payments, maintenance payments through the payment gateway(s) authorized by Kobble. In this regard, the Users are asked to provide customary billing information the details of the user like an address to whom the physical card or any other purchases on kobble website has to be delivered. The Users must provide accurate, current, and complete information of the address while making the payment through the Site and it shall be the User’s obligation to keep this information up-to-date at all times. The Users are solely responsible for the accuracy and completeness of the information provided by them and Kobble shall not be responsible for any loss suffered by the User as a result of any incorrect information, including payment information provided by the Users.
            <br /><br />
            Except for Kobble’s limited role in processing & creating a virtual card or digital card as per the information given by the user. The user is entitled to provide rightful data to create the card and Kobble is not responsible or liable of any such data provided by the user. Further to the information, pertaining to the uploading of the User’s or organization Logo onto the cards is as per the logo uploaded by the user, Kobble don’t hold any responsibility or liability for the Quality, dimensions, aesthetics, sizing, colour coding, breaching of legal rights, breaching of privacy rights, breaching of copyrights, breaching of trademark for the use of logo uploaded by the users. It's solely on the user uploading the logo to the Kobble website or mobile app to create the Kobble cards (virtual or digital or physical).
            <br /><br />
            Except for Kobble’s limited role in processing & creating the user accounts with Kobble with the information provided by the user including personal details & social media details and any other account details of online platform or mediums, and required data for the use of Kobble products or mobile app, cards (Virtual & physical), Kobble websites & links. All such information provided by the user is liable and on sole discretion of the user. Any false data or misrepresentation of data or fake data or misleading data given by the user is solely responsible to the user and Kobble has no liability or responsibility for such data.
            <br /><br />
            Except for Kobble’s limited role in processing the payments that registered Users authorize or initiate, Kobble is not involved in any underlying transaction between the User, any other User, any third person or any service providers. Kobble is not a bank and does not offer any banking or related services. Kobble may use the services of one or more third parties (each a "Processor") to provide the Service and process the User’s transactions. Further, Kobble does not guarantee payment on behalf of any registered User, other User or Processor and explicitly disclaims all liability for any act or omission of any User or Processor. Kobble acts solely as an intermediary which facilitates payments between the registered Users making the payment and the intended third-party beneficiaries.
            <br /><br />ii. Authorization: The User acknowledges that Kobble is authorized by the User to hold, receive and disburse funds in accordance with the User’s payment instructions provided through the Site for the purposes of facilitating the transfer of monies to the intended beneficiary as specified by the User on the Site. [The authorization given by the Users permits Kobble (a) to debit or credit the User’s balance, bank account, any credit card, debit card, or other payment cards or any other payment method that Kobble accepts] or (b) to process payment transactions that the Users authorize by generating an electronic funds transfer.
            <br /><br />iii. Fees: The User agrees that they may be charged a service charge by Kobble for using the Site to use the Kobble platform and create Kobble cards & gadgets
            <br /><br />iv. Transaction Limits: Kobble may delay, suspend or reject a transaction for any payment(s) for any reason, including without limitation if Kobble suspects that the transaction subjects it to financial or security risk or is unauthorized, fraudulent, suspicious, unlawful, in violation of the terms of this Agreement subject to dispute or otherwise unusual.
            <br /><br />v. Chargebacks: The amount of a transaction may be charged back or reversed to the User (a "Chargeback") if the transaction (a) is disputed by the user, (b) is reversed for any reason, (c) was not authorized or Kobble has reason to believe that the transaction was not authorized, or (d) is allegedly unlawful, suspicious, or in violation of the terms of this Agreement. The Users owe Kobble and will immediately pay Kobble the amount of any Chargeback and any associated fees, fines, or penalties assessed by Kobble’s Processor, processing financial institutions, or MasterCard, Visa, Rupay, American Express, Discover, and other payment card networks, associations, or companies ("Networks"). The Users agree to assist Kobble when requested, at the User’s expense, to investigate any of the User’s transactions processed through the Service. For Chargebacks associated with cards, Kobble will work with the Users to contest the Chargeback with the Network or issuing banks should the User choose to contest the Chargeback. In this regard, Kobble will request necessary information from the User to contest the Chargeback and the User’s failure to timely assist Kobble in investigating a transaction, including without limitation providing necessary documentation within 5 business days of Kobble’s request, may result in an irreversible Chargeback.
            <br /><br />vi. Liability: The Users agree not to hold Kobble responsible and/or liable for any issue or claim arising out of any dispute whatsoever between the User and the Processor and/or the User and the User’s bank or financial institution or any organization.
            <br /><br />Additionally, please note that Kobble shall not be responsible for any additional fees or charges deducted by the Processors while processing payments in connection with the User’s transaction and Kobble disclaims all liability in this regard. Further, the Users may also be subject to additional terms and conditions imposed by such Processors and the Users should review these terms and conditions before authorizing any payments through the Processors.
            <br /><br />vii. Fraud and restrictions: Kobble may ask for any additional documents if required to inspect any fraudulent account creation or usage of Kobble products and to restrict or limit the access of such accounts.
            <br /><br />viii. Refunds: [In case, Kobble is not able to create a virtual account due to any technical difficulties, Kobble will refund the payment back to the source.] a refund ID is generated and sent to the Users through email and SMS.
            <br /><br />ix. The Users acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of the User’s access to and use of the Payment Services remains with the Users. If the Users permit or authorize another person to use their Kobble account in any way, the Users shall be responsible for the actions taken by that person. Neither Kobble nor any other party or Processor involved in creating, producing, or delivering the payment Services will be liable for any incidental, special, exemplary, or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with these payments & Kobble terms & conditions.

          </p>
          <h3>CANCELLATION POLICY</h3>
          <p>
            Any user on the Kobble website or mobile app or platforms can create an account and generate Kobble cards (physical & virtual) and get access to the cards post completion of payment. Digital or virtual card will be availed and can be accessed by the user instantly & physical cards will be delivered to the user as per the address given by the thirty-party delivery partner. Users can cancel the cards or purchases made on the Kobble website or mobile apps or Kobble platforms within 48 hrs from the payment or receipt of invoice whichever is earlier.
          </p>
          <h3>DISCLAIMER</h3>
          <p>
            <br />(a) The information available on the Site or mobile app or any of the Kobble platforms in relation to the Services are mere guidelines for the purposes of providing best of services
            <br />(b)Kobble does not warrant the Services or the results obtained from the use or that the Services will meet the User’s expectations or requirements on the Site or mobile app or any of the Kobble platforms & Kobble cards (virtual & physical) will be uninterrupted or free from any technical error.
            <br />(c) Kobble is not responsible for the accuracy or reliability of any third-party reports, market information, studies and analysis made available about Site or mobile app or any of the Kobble platforms
            <br />(d) Kobble does not have the obligation to physically meet, conduct background or police verification of the Users. Kobble accepts User Information in good faith and shall not be held responsible with regard to the bonafides of the Users.
            <br />(e) Kobble shall not be liable for any damages, expenses, costs or losses incurring from the User’s engagement in Prohibited Conduct and availing services of Third-Party Service Providers.
            <br />(f) Kobble shall not be held liable or responsible for indemnifying, refunding or reimbursing any User for any loss, damages or expenses suffered or incurred by such User as a consequence of the suspension or deactivation of the User’s Account.
            <br />(g) Kobble shall not be liable for any third party fees, costs, charges or expenses incurred by the User for accessing the Site or mobile app or any of the Kobble platforms or availing the Services. Kobble shall not be liable in respect of any disputes or legal proceedings between the User and such third party for any reason whatsoever and any such disputes or proceedings shall be resolved outside the Site or mobile app or any of the Kobble platforms without any reference or recourse to Kobble.
            <br />(h) Kobble does not hold the responsibility or liability for the content or data shown on user accounts or uploaded by the users related to personal or social information as the data is entered by the user. Any wrong data or false data or misleading data or content breach or infringement, legal rights infringement, shared or used by users or registered accounts of Kobble using Kobble Site or mobile app or any of the Kobble platforms or Kobble cards (physical or virtual) or Kobble gadgets or any of the intellectual properties of Kobble. Its sole responsibility of the users or account holders with Kobble.
            <br />(i) Kobble shall not hold any responsibility of the content provided and entered by the users on Site or mobile app or any of the Kobble platforms in cases of ant technical errors or infringements. By using the Site or mobile app or any of the Kobble platforms it deems to be user agress the terms & conditions of Kobble.
            <br />(j) Kobble neither has access to, nor does the Agreement govern the use of cookies or other tracking technologies that may be placed by Third Party Service Providers on the Site. These parties may permit the User to opt out of tailored advertisement at any time, however, to the extent advertising technology is integrated into the Services, the User may still receive advertisements and related updates even if they choose to opt-out of tailored advertising. Kobble assumes no responsibility or liability whatsoever for the User’s receipt or use of such tailored advertisements.
            <br />(k) Kobble shall not be liable for the non-performance or defective or late performance of any of the Services or any of its obligations under this Agreement to such extent and for such period of time if such non-performance, defective performance or late performance is due to acts of God, war (declared or undeclared), civil insurrection or unrest, acts (including failure to act) of any Governmental Authority, riots, revolutions, fire, floods, strikes, lock-outs or industrial action.

          </p>
          <h3>CONTENT ON THE SITE</h3>
          <p>
            <br />Kobble shall endeavor to ensure that the Content on its Site is monitored to ensure that the same is not in contravention of Applicable Law and this Agreement. In this regard, the Users represent and warrant that they shall not do any of the following via the Site or mobile application or any other Kobble platforms offered to the users otherwise in connection with the Service:
            <ul><li>
              <br />(i) attempt or help anyone else attempt to gain unauthorized access to the Site or Kobble’s related systems or networks (including without limitation the impersonation of another User or use of a false identity or address to gain access to the Site);
              <br />(ii) use the Site to violate the Intellectual Property Rights of any person (including without limitation posting pirated music or computer programs or links to such material on Site or on the User’s Profile);
              <br />(iii) send spam or otherwise duplicative or unsolicited messages in violation of Applicable Laws;
              <br />(iv) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights;
              <br />(v) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents, or programs;
              <br />(vi) interfere with or disrupt the integrity or performance of the Service or the data contained therein;
              <br />(vii) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service or any content contained in or made available through the Service in any way;
              <br />(viii) modify or make derivative works based upon the Service or the Content of Kobble;
              <br />(ix) create internet "links" to the Site or "frame" or "mirror" any Content on any other server or wireless or internet-based device; or
              <br />(x) reverse engineer or access the Service in order to (a) build a product competitive with the Service, (b) build a product using ideas, features, functions or graphics similar to those of the Service, or (c) copy any ideas, features, functions or graphics contained in the Service.
            </li></ul>
          </p>
          <h3>
            USER-GENERATED CONTENT
          </h3>
          <p>
            <br />The Site or the mobile app or the Kobble cards (Virtual or physical) may contain user-generated content (“UGC”) which Kobble does not pre-screen. These data do not represent Kobble’s views, opinions, beliefs, morals or values. Kobble does not claim any ownership rights in the text, files, images, or Logos, including contact numbers, social media accounts, photos, videos, sounds, musical works or any UGC that the Users submit or publish on the Site. After posting any UGC on the Site, the Users continue to own the rights that the Users may have in that UGC, subject to the limited license set out here. Kobble shall do its best to monitor, edit or remove such UGC where Kobble considers it appropriate or necessary to do so. Kobble does not promise that the content in or on the Site is accurate, complete or updated, that it will not offend or upset any person, or that it does not infringe the Intellectual Property Rights of third parties.
            The Users hereby expressly acknowledge and agree that Kobble will not be liable for the User’s losses or damages (whether direct or indirect) caused by any activity undertaken by the User on the basis of any UGC.
          </p>
          <h3>INTELLECTUAL PROPERTY</h3>
          <p>
            <ul>
              <li>
                <br />i. Kobble respects the Intellectual Property Rights of others and asks its Users to do the same. The User shall have the sole responsibility for the Intellectual Property ownership or right to use of any information that the Users submit via the Site or mobile app or any of the Kobble platforms and the Users may not use the Site or mobile app or any of the Kobble platforms to transmit or reproduce someone else's Intellectual Property.
                <br />ii. The User shall be held liable for acts including but not limited to those set out below herein, for any such infringement of Kobble’s Intellectual Property Rights:
                <br /><br />(a) misrepresentation of User Information or Content;
                <br />(b) using the Content directly or indirectly, publicly or privately for charging from the Users;
                <br />(c) using the Content directly or indirectly, publicly or privately for charging from any third party;
                <br /><br />iii. The User shall not upload, post or otherwise make available on the Site, Intellectual Property without the express permission of the concerned owner and the User shall be solely liable for any damage resulting from any infringement of such Intellectual Property.
                <br />iv. Kobble reserves the right to initiate appropriate legal proceedings against any User or third party for an infringement of its Intellectual Property Rights, in accordance with Applicable Law.
                <br />v. The User further accepts and agrees that Kobble shall have Intellectual Property Rights on all information and data provided or shared by the User on the Site or mobile app or any of the Kobble platforms.

              </li>
            </ul>
          </p>
          <h3>
            OPERATIONAL HAZARDS/ COMPUTER VIRUS ATTACKS
          </h3>
          <p><ul>
            <li>
              <br />i. Kobble does not warrant in any manner whatsoever that the Site or any other software utilized by Kobble for internal purposes, shall at all times remain free from any harmful components and operational hazards such as Computer Virus, worms, trojans and such related components that might be a threat to the information available with Kobble.
              <br />ii. Kobble shall endeavor to keep the Site or mobile app or any of the Kobble platforms secured against any possible bugs, viruses or other technical problems in compliance with the best practices of this industry.
              <br />iii. kobble shall not be held liable for any damage or injury caused due to performance, failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, Computer Virus, link failure, site crash, malfunctioning or software/ hardware, unavailability of network, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information, whether resulting in whole or in part from negligence or otherwise.
              <br />iv. Kobble shall not be liable for delay or damage in the delivery of physical kobble cards or kobble gadgets. The third party delivery partner holds the responsibility as per the terms & conditions or agreement between the Kobble or Invent technologies.
            </li>
          </ul> </p>
          <h3>LIMITATION OF LIABILITY</h3>
          <p>
            <br />'Kobble’s total aggregate liability under this Agreement and in relation to anything which Kobble has done or not done in relation with this Agreement shall be limited to the fees paid by the User for availing the Services in relation to which such liability of Kobble or Invent technologies arises.
          </p>
          <h3>INDEMNITY</h3>
          <p>
            <br />Kobble and its officers, directors, employees and agents collectively at all times shall be held indemnified against any losses, costs, expenses, claims, suits and/or damages (including reasonable attorney fees)incurred and/or suffered by Kobble, whether directly or indirectly, resulting from, an act or a failure to act, of any User, person or Third-Party Service Provider for any reason whatsoever and against any claims, suits and/or legal proceedings initiated by third parties inclusive of but not limited to the User’s:
            <ul><li>
              <br />(a) breach of any terms of this Agreement;
              <br />(b) third party claims arising from an infringement or alleged infringement of such third party’s Intellectual Property;
              <br />(c) claims made by any Government Authority due to the User’s violation of Applicable Law; or
              <br />(d) actual or alleged gross negligence or willful misconduct in connection with the User’s use of the Services or this Agreement.
              <br />(e) a fraudulent act committed by a User which results in loss or damage, suffered or incurred by any other User or by any third party.
            </li></ul>
          </p>
          <h3>USER GRIEVANCE</h3>
          <p>
            <br />Any User grievance relating to the discrepancies or misuse of information so provided to Kobble may be addressed to the grievance officer, whose details are provided below appointed by Kobble for this purpose:<br />
            <b>Name: </b> David (Managing Partner)<br />
            <b>Contact Number: </b> 8125655446<br />
            <b>Email Id: </b> hello@kobble.in<br />
            The grievance officer shall address the grievance within one month of the date of receipt of such grievance from the User.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsNConditions;